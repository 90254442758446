import React from 'react';
import PropTypes from 'prop-types';

// import books from '../../images/policyslides/policy-4-flag-parliment.png'

const Slide = ({ children, transition = 'fade' }) => (
  <section data-transition={transition}  >{children}</section>
);

Slide.propTypes = {
  children: PropTypes.node,
  transition: PropTypes.string,
};

export default Slide;
