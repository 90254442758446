import React from 'react';
// import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

// import pic1 from '../../images/overview/overview-13-ecocanada.png'
// import pic2 from '../../images/overview/overview-13-OEG.png'

const Slide4 = () => (
    <Slide >
        <section >
            <div style={{ height: '50px' }} />
            <div style={{ margin:'auto' }}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '45px', width: '90%', color: '#276e42', translate: '3%' }} ><strong>Join Us in Making a Difference</strong></div>
                <div style={{ height: '30px' }} />


                <p className='m-3' style={{ textAlign: 'left', fontSize: '25px' }}>
                    <strong>Your participation is vital.</strong>
                </p>
                <div style={{ height: '10px' }} />
                <p className='m-3' style={{ textAlign: 'left', fontSize: '25px', width: '90%' }}>
                    By learning about ZEVs and considering their adoption, you can contribute to a cleaner, greener future. Explore our resources to discover how ZEVs can benefit your community or busness. Together, we
                    can drive positive change and make a lasting impact.
                </p>
                <p className='m-3' style={{ textAlign: 'left', fontSize: '25px', width: '90%'}}>
                Learn more about the information this website is based on by clicking here:
                </p>
                <p className='m-3' style={{ textAlign: 'left', fontSize: '25px', width: '90%'}}><a href='https://eco.ca/new-reports/zero-emission-vehicle-awareness-initiative-literary-manuscript/'>https://eco.ca/new-reports/zero-emission-vehicle-awareness-initiative-literary-manuscript/</a></p>
            </div>
        </section>
    </Slide>
);

export default Slide4;
