import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';


const Slide1 = () => (
    <Slide >
        <section >
        <div style={{ height: '50px' }} />
            <div style={{ margin: 'auto' }}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '70%', color: '#276e42', translate: '3%' }} ><strong>What Are Zero-Emission Vehicles?</strong></div>
                
                <div style={{ height: '50px' }} />
                <div style={{ backgroundColor: '#e6e6e6', width: '100px', height: '300px', position: 'absolute', zIndex: '-999', top: '45%', left: '-2%' }} />

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px' }}>
                    Zero-emission vehicles (ZEVs) for medium and heavy-duty vehicles include<strong> electric trucks, hydrogen fuel cell trucks, </strong>and <strong>plug-in hybrid trucks.</strong>
                </p>
                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px' }}>
                    These vehicles produce no direct emissions, helping to reduce pollution and promote cleaner air.
                </p>

            </div>
        </section>
    </Slide>
);

export default Slide1;
