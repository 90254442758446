import React, { useState } from 'react'; // 
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Slide from '../../Components/RevealComponents/Slide';

import pic from '../../images/techinnoslides/tech-16-ruralbackground.png'

const popover = (
    <Popover >
        <Popover.Body>
        Medium- and Heavy-Duty Zero-Emission Vehicle
        </Popover.Body>
    </Popover>
);

const Slide3 = () => {

    const [showHFC, setHFC] = useState(false);
    const [showRA, setRA] = useState(false);
    const [showIL, setIL] = useState(false);
    const [showCC, setCC] = useState(false);

    // Similar to componentDidMount and componentDidUpdate:
    function clickShowHFC(v) {
        setHFC(v);
    }

    function clickShowRA(v) {
        setRA(v);
    }

    function clickShowIL(v) {
        setIL(v);
    }

    function clickShowCC(v) {
        setCC(v);
    }

    return (
        <Slide >
            <section >
                <div style={{ margin: 'auto' }} >
                    <div className='m-3 px-3' style={{ textAlign: 'left', fontSize: '50px', translate: '-15px', borderLeft: '10px solid #276e42', borderRadius: '5px' }} ><strong>MHDZEV and Tech</strong></div>
                    <div style={{ height: '20px' }} />
                    <p className='m-3' style={{ textAlign: 'left' }}>
                        <OverlayTrigger  placement="bottom" overlay={popover}>
                            <strong style={{ color: '#276e42' }}>MHDZEV</strong>
                        </OverlayTrigger> make up a significant portion of transportation emissions.
                    </p>
                    <p className='m-3' style={{ textAlign: 'left' }}>
                        The largest use cases for MHDZEV
                    </p>

                    <div style={{ height: '20px' }} />

                    <div className='m-3' style={{ width: '70%' }}>
                        <div style={{ textAlign: 'left' }}>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                                Energy Density</p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                                Efficiency</p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                                Lifespan</p>
                        </div>
                    </div>
                </div>
            </section>


            <section data-background-image={pic} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1} >

                <div className='jusity-content-space-between'>
                    <div style={{ textAlign: 'left', margin: '50px', position: 'top' }}>
                        <div className='d-flex' style={{ width: '60%', fontSize: '50px', color: 'white', backgroundColor: '#276e42', padding: '20px' }} >
                            <p><strong >ZEV Tech <br /> in Rural and Remote Areas</strong></p>
                        </div>

                    </div>
                    <div style={{ height: '150px' }} />
                    <div style={{ width: '90%', fontSize: '40px', textAlign: 'left', margin: 'auto', padding: '30px' }}>
                        Rural and remote areas encounter unique challeges that hinder the swift adoption of ZEVs.
                    </div>
                </div>



            </section>

            <section data-background-image={pic} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1} >
                <div style={{ textAlign: 'left', fontSize: '40px', width: '70%', margin: '30px', translate: '60px' }}>
                    There are 4 primary challenges to adopting ZEVs in <strong style={{ color: '#276e42' }}>Rural and Remote Areas</strong>
                </div>
                <div style={{ fontSize: '25px', margin: '30px' }}>Click on each box to see how innovation can address the challenges</div>
                {!showHFC && !showRA && !showIL && !showCC ?
                    <Container fluid>

                        <Row>
                            <Button variant='light'
                                onClick={() => { clickShowHFC(true) }}
                                style={{ border: '1px solid #e6e6e6', backgroundColor: '#e6e6e6', color: 'black', fontSize: '40px', fontWeight: 'bold', height: '150px', width: '325px', margin: 'auto', boxShadow: '-5px -5px 8px #888888' }}
                            >
                                High Upfront Cost
                            </Button>
                            <Button variant='light'
                                onClick={() => { clickShowRA(true) }}
                                style={{ border: '1px solid #e6e6e6', backgroundColor: '#e6e6e6', color: 'black', fontSize: '40px', fontWeight: 'bold', height: '150px', width: '325px', margin: 'auto', boxShadow: '5px -5px 8px #888888' }}
                            >
                                Range Anxiety
                            </Button>
                        </Row>
                        <Row >
                            <div style={{ height: '50px' }} />
                        </Row>
                        <Row>
                            <Button variant='light'
                                onClick={() => { clickShowIL(true) }}
                                style={{ border: '1px solid #e6e6e6', backgroundColor: '#e6e6e6', color: 'black', fontSize: '40px', fontWeight: 'bold', height: '150px', width: '325px', margin: 'auto', boxShadow: '-5px 5px 8px #888888' }}
                            >
                                Infrastructure Limitations</Button>
                            <Button variant='light'
                                onClick={() => { clickShowCC(true) }}
                                style={{ border: '1px solid #e6e6e6', backgroundColor: '#e6e6e6', color: 'black', fontSize: '40px', fontWeight: 'bold', height: '150px', width: '325px', margin: 'auto', boxShadow: '5px 5px 8px #888888' }}
                            >
                                Cold Climates
                            </Button>
                        </Row>
                    </Container> : null
                }
                {showHFC ?
                    <Container fluid>
                        <div style={{ backgroundColor: '#e6e6e6', boxShadow: '-5px -5px 8px #888888' }}>
                            <p><strong>High Upfront Cost</strong></p>
                            <p style={{ fontSize: '30px', width: '60%', margin: 'auto' }}>With advancements in EV, they can be cheaper than internal combustion engine vehicles due to lower opeating and maintenance costs.</p>
                            <Button variant='light' className='m-3' onClick={() => { clickShowHFC(false) }} style={{ backgroundColor: '#276e42', color: 'white' }}>Close</Button>
                        </div>
                    </Container> : null

                }

                {showRA ?
                    <Container fluid>
                        <div style={{ backgroundColor: '#e6e6e6', boxShadow: '5px -5px 8px #888888' }}>
                            <p><strong>Range Anxiety</strong></p>
                            <p style={{ fontSize: '30px', width: '70%', margin: 'auto' }}>
                                Studies indicate that the current EV range can cover most driving needs of rural residents. For example, an Australian study found over 99% of residents could reach their closest hub town with existing
                                EV technology.
                            </p>
                            <Button variant='light' className='m-3' onClick={() => { clickShowRA(false) }} style={{ backgroundColor: '#276e42', color: 'white' }}>Close</Button>
                        </div>
                    </Container> : null

                }

                {showIL ?
                    <Container fluid>
                        <div style={{ backgroundColor: '#e6e6e6', boxShadow: '-5px 5px 8px #888888' }}>
                            <p><strong>Infrastructure Limitations</strong></p>
                            <p style={{ fontSize: '30px', width: '60%', margin: 'auto' }}>
                                Charging infrastructure is expanding, supported by federal incentives aimed at increasing accessibility in rural areas.
                            </p>
                            <Button variant='light' className='m-3' onClick={() => { clickShowIL(false) }} style={{ backgroundColor: '#276e42', color: 'white' }}>Close</Button>
                        </div>
                    </Container> : null

                }

                {showCC ?
                    <Container fluid>
                        <div style={{ backgroundColor: '#e6e6e6', boxShadow: '5px 5px 8px #888888' }}>
                            <p><strong>Cold Climates</strong></p>
                            <p style={{ fontSize: '30px', width: '75%', margin: 'auto' }}>
                                Advancements in battery technology are reducing cold climate challenges for EVs. Enhanced thermal management in lithium-ion batteries and the promise of solid-state
                                batteries improve performance in extreme cold.
                            </p>
                            <Button variant='light' className='m-3' onClick={() => { clickShowCC(false) }} style={{ backgroundColor: '#276e42', color: 'white' }}>Close</Button>
                        </div>
                    </Container> : null

                }

            </section>
        </Slide>
    );
}

export default Slide3;
