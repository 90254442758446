import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

import parliment from '../../images/policyslides/policy-4-flag-parliment.png'

const Slide3 = () => (
    <Slide >
        <section data-prevent-swipe data-background-image={parliment} data-background-size="70%" data-background-position="left" data-background-repeat="no repeat" data-background-opacity={1}  >
            <div className='r-stack'>
                <div data-prevent-swipe className='fragment fade-out' data-fragment-index="0" style={{ backgroundColor: 'lightgrey', height: '60%', width: '50%',  margin: 'auto', padding: '20px' }}>
                    <p style={{ translate: '0px -80px' }}>
                        <strong style={{ color: 'white', backgroundColor: 'black', fontSize: '80px', paddingTop: '10px', paddingLeft: '10px', paddingRight: '30px'  }}>Canada</strong>
                    </p>
                    <p style={{ translate: '0px -80px', paddingLeft: '10px', textAlign: 'left', fontSize: '50px' }}>
                        <strong >is investing in all of these areas and more...</strong>
                    </p>
                </div>

                <div data-prevent-swipe className='fragment fade-in-then-out' data-fragment-index="0">
                    <div style={{ textAlign: 'right', margin: '10px', width: '50%', float: 'right' }}>
                        <p style={{ padding: '30px', overflow: 'visible', fontSize: '50px' }}>With the goal of 100% new Light Duty vehicles being Zero Emission by </p>
                        <p><strong style={{ padding: '30px', overflow: 'visible', fontSize: '70px' }}>2035</strong></p>

                    </div>
                    <div style={{ backgroundColor: 'lightgrey', width: '10%', height: '590px', margin: '10px', position: 'absolute', right: '10px', color: 'lightgray', zIndex: '-1' }} >a</div>

                </div>
                <div data-prevent-swipe className='fragment fade-up' data-fragment-index="2">
                    <h1 style={{ textAlign: 'right', fontSize: '50px', paddingRight: '25px' }}><strong>This is being accomplished by</strong></h1>
                    <div style={{ backgroundColor: 'lightgrey', width: '10%', height: '500px', margin: '10px', position: 'absolute', right: '-20px', top: '-40px', color: 'lightgray', zIndex: '-1' }} >a</div>
                    <div className='r-stack'>

                        <div data-prevent-swipe className='fragment fade-in-then-out' data-fragment-index="2">
                            <div style={{ margin: '10px', width: '30%', float: 'right' }}>
                                <p style={{ textAlign: 'right', fontSize: '45px', width: '390px' }}>Setting minimum ZEV sales targets</p>

                            </div>

                        </div>
                        <div data-prevent-swipe className='fragment fade-in-then-out' data-fragment-index="3"  >
                            <div style={{ margin: '10px', width: '40%', float: 'right' }}>
                                <p style={{ textAlign: 'right', fontSize: '45px', width: '390px' }}>Infrastructure investment</p>
                            </div>

                        </div>

                        <div data-prevent-swipe className='fragment fade-in-then-out' data-fragment-index="4"  >
                            <div style={{ margin: '10px', width: '40%', float: 'right' }}>
                                <p style={{ textAlign: 'right', fontSize: '45px', width: '390px' }}>Incentives</p>
                            </div>

                        </div>

                        <div data-prevent-swipe className='fragment fade-in-then-out' data-fragment-index="5">
                            <div style={{ margin: '10px', width: '40%', float: 'right' }}>
                                <p style={{ textAlign: 'right', fontSize: '45px', paddingRight: '30px' }}>Research into alternative fuel opportunities</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Slide>
);

export default Slide3;
