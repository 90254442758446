import React from 'react';
import TitleSlide from './TitleSlide';

import HomeSlide from './HomeSlide';
import Class2B from './Class2B';
import Class3 from './Class3';
import Class4 from './Class4';
import Class5 from './Class5';
import Class6 from './Class6';
import Class7 from './Class7';
import Class8 from './Class8';
import Class9 from './Class9';

export default [
  <TitleSlide key="1"/>,
  <HomeSlide key="2"/>,
  <Class2B key="3"/>,
  <Class3 key="4"/>,
  <Class4 key="5"/>,
  <Class5 key="6"/>,
  <Class6 key="7"/>,
  <Class7 key="8"/>,
  <Class8 key="9"/>,
  <Class9 key="10"/>
];
