import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import pic from '../../images/overview/overview-12-icon.png'


const TitleSlide = () => (
  <Slide >
    <section >
      <Image src={pic} alt='Can Icon' style={{ position: 'absolute', left: '1%', top: '1%', width: '10%', height: '20%', zIndex: '-999' }} />

      <div style={{ margin: 'auto', translate: '0 15%', width: '90%' }}>

        <p style={{ fontSize: '40px', padding: '15px', color: 'white', backgroundColor: '#276e42', textAlign: 'left' }}><strong>Welcome to the Zero-Emission Vehicle Awareness Initiative.</strong></p>
        <p style={{ fontSize: '25px', textAlign: 'left' }}>
          This initiative is dedicated to increasing awareness and encouraging the adoption of zero-emission vehicles (ZEVs) for medium and heavy-duty applications.
        </p>
        <p style={{ fontSize: '25px', textAlign: 'left' }}>
          Our focus is on supporting rural and remote areas, Indigenous communities, farming regions, the trucking industry, and small businesses.
        </p >
        <p style={{ fontSize: '25px', textAlign: 'left' }}>
          We aim to provide you with the information and resources needed to make informed decisions about ZEVs.
        </p>
        <div style={{ fontSize: '10px',  fontStyle: 'italic', textAlign: 'left' }}>
          Last updated August, 2024
        </div>
      </div>
     
    </section>

    
  </Slide>
);

export default TitleSlide;
