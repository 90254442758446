import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Slide from '../../Components/RevealComponents/Slide';

import globe from '../../images/econfactorslides/economic-1-globe.png'

import '../../Themes/dashboard.css';
import '../../animation.css';

const Slide2 = () => {
    const [showAnswer, setShowAnswer] = useState(false);
    const [array] = useState([]);
    const [canada, setCanada] = useState(false);
    const [us, setUS] = useState(false);
    const [uk, setUK] = useState(false);
    const [norway, setNorway] = useState(false);
    const [japan, setJapan] = useState(false);
    const [china, setChina] = useState(false);
    const [greece, setGreece] = useState(false);
    const [germany, setGermany] = useState(false);
    const [korea, setKorea] = useState(false);
    const [mexico, setMexico] = useState(false);

    function clickRadio(name, v) {
        if (!v && array.includes(name)) {
            const idx = array.indexOf(name);
            array.splice(idx, 1);
        }

        switch (name) {
            case "Canada":
                setCanada(v);
                break;
            case "United States":
                setUS(v);
                break;
            case "United Kingdom":
                setUK(v);
                break;
            case "Norway":
                setNorway(v);
                break;
            case "Japan":
                setJapan(v);
                break;
            case "China":
                setChina(v);
                break;
            case "Greece":
                setGreece(v);
                break;
            case "Germany":
                setGermany(v);
                break;
            case "Korea":
                setKorea(v);
                break;
            case "Mexico":
                setMexico(v);
                break;
            default:
                break;
        }
    }

    function manageArray(v) {
        if (array.length < 3) {
            array.push(v)

        }
        else {
            clickRadio(array[0], false)
            array.push(v)
        }
    }

    return (
        <Slide >
            <Image src={globe} className='fade-in-image' alt='Graph' style={{ position: 'absolute', bottom: '-2%', right: '-15%', height: '80%', width: '80%', zIndex: '-999' }} />

            <section className='h-100 d-flex flex-column justify-content-start align-items-center' data-auto-animate data-background-color="black">
                <div id="text" style={{ color: 'white', fontSize: '50px', fontWeight: 'bold', textAlign: 'left', width: '60%', translate: '0 150%' }}>
                    Market trends differ greatly across the globe.
                </div>
            </section>
            <section className='h-100 d-flex flex-column justify-content-center align-items-between' data-auto-animate data-background-color="black">
                <div className='my-3' style={{ color: 'white', fontSize: '50px', fontWeight: 'bold', textAlign: 'left' }}>
                    Do you know the top 3 countries leading the charge in ZEV adoption?
                </div>
                <div className='my-3 fragment fade-up' data-fragment-index="0" style={{ backgroundColor: 'rgb(0, 0, 0, 0.4)', padding: '10px' }}>
                    {!showAnswer ?

                        <Form className="m-2 p-2 d-flex"  >
                            <Col xs="7" lg="7" >
                                <Form.Check checked={canada} onChange={(e) => { manageArray(e.target.id) }} onClick={(e) => clickRadio(e.target.id, !canada)} style={{ fontSize: '30px', color: 'white', textAlign: 'left', padding: '5px', fill: '#262626' }} type='radio' id="Canada" label="Canada" />
                                <Form.Check checked={us} onChange={(e) => { manageArray(e.target.id) }} onClick={(e) => clickRadio(e.target.id, !us)} style={{ fontSize: '30px', color: 'white', textAlign: 'left', padding: '5px' }} type='radio' id="United States" label="United States" />
                                <Form.Check checked={uk} onChange={(e) => { manageArray(e.target.id) }} onClick={(e) => clickRadio(e.target.id, !uk)} style={{ fontSize: '30px', color: 'white', textAlign: 'left', padding: '5px' }} type='radio' id="United Kingdom" label="United Kingdom" />
                                <Form.Check checked={norway} onChange={(e) => { manageArray(e.target.id) }} onClick={(e) => clickRadio(e.target.id, !norway)} style={{ fontSize: '30px', color: 'white', textAlign: 'left', padding: '5px' }} type='radio' id="Norway" label="Norway" />
                                <Form.Check checked={japan} onChange={(e) => { manageArray(e.target.id) }} onClick={(e) => clickRadio(e.target.id, !japan)} style={{ fontSize: '30px', color: 'white', textAlign: 'left', padding: '5px' }} type='radio' id="Japan" label="Japan" />
                            </Col>
                            <Col xs="3" lg="3">
                                <Form.Check checked={china} onChange={(e) => { manageArray(e.target.id) }} onClick={(e) => clickRadio(e.target.id, !china)} style={{ fontSize: '30px', color: 'white', textAlign: 'left', padding: '5px' }} type='radio' id="China" label="China" />
                                <Form.Check checked={greece} onChange={(e) => { manageArray(e.target.id) }} onClick={(e) => clickRadio(e.target.id, !greece)} style={{ fontSize: '30px', color: 'white', textAlign: 'left', padding: '5px' }} type='radio' id="Greece" label="Greece" />
                                <Form.Check checked={germany} onChange={(e) => { manageArray(e.target.id) }} onClick={(e) => clickRadio(e.target.id, !germany)} style={{ fontSize: '30px', color: 'white', textAlign: 'left', padding: '5px' }} type='radio' id="Germany" label="Germany" />
                                <Form.Check checked={korea} onChange={(e) => { manageArray(e.target.id) }} onClick={(e) => clickRadio(e.target.id, !korea)} style={{ fontSize: '30px', color: 'white', textAlign: 'left', padding: '5px' }} type='radio' id="Korea" label="Korea" />
                                <Form.Check checked={mexico} onChange={(e) => { manageArray(e.target.id) }} onClick={(e) => clickRadio(e.target.id, !mexico)} style={{ fontSize: '30px', color: 'white', textAlign: 'left', padding: '5px' }} type='radio' id="Mexico" label="Mexico" />
                            </Col>
                            <Col lg="2" className='align-self-end'>
                                <Button disabled={array.length !== 3} variant="secondary" onClick={() => setShowAnswer(true)}>Submit</Button>
                            </Col>
                        </Form>
                        : null
                    }

                    {showAnswer && array.includes('Norway') && array.includes('China') && array.includes('Korea') ?

                        <div style={{ color: 'white', textAlign: 'left' }}>
                            <p style={{ fontSize: '40px', fontWeight: 'bold' }}>Correct!</p>
                            <p style={{ fontSize: '30px' }}>EV Adoption is especially strong in <strong>Norway</strong>, making up most newly sold passenger vehicles, and it is growing quickly in <strong>China</strong>. <strong>Korea</strong> leads in fuel cell EV (FCEV) growth with over half of the global FCEV stock.</p>
                            <Button variant="secondary" onClick={() => setShowAnswer(false)}>Close</Button>
                        </div> : null
                    }

                    {showAnswer && !(array.includes('Norway') && array.includes('China') && array.includes('Korea')) ?
                        <div style={{ color: 'white', textAlign: 'left' }}>
                            <p style={{ fontSize: '40px', fontWeight: 'bold' }}>Incorrect.</p>
                            <p style={{ fontSize: '30px' }}>EV Adoption is especially strong in <strong>Norway</strong>, making up most newly sold passenger vehicles, and it is growing quickly in <strong>China</strong>. <strong>Korea</strong> leads in fuel cell EV (FCEV) growth with over half of the global FCEV stock.</p>
                            <Button variant="secondary" onClick={() => setShowAnswer(false)}>Close</Button>
                        </div> : null
                    }
                </div>


            </section>
        </Slide>
    );
}

export default Slide2;