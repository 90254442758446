import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

import row1 from '../../images/econimpactslides/environmental-10-diesel.png'
import row2 from '../../images/econimpactslides/environmental-10-renewables.png'

import './table.css'

const Slide4 = () => (
    <Slide>
        <section className='flex-column align-items-center justify-content-center'>
            <div style={{ height: '100px'}} /> 
            <div style={{ fontWeight: 'bold', fontSize: '50px', textAlign: 'left'}}>
                The environmental impacts of electric vehicles are also influenced by the local electricity mix.
            </div>
            <div style={{ height: '100px'}} /> 
            <div>
                <table className="border-none" style={{ margin: 'auto' , fontSize: '40px', textAlign: 'center', height: '100%', width: '100%', padding: '10px' }}>
                    <thead>
                        <tr > 
                            <th >Grid Type</th>
                            <th >EV Impact</th>
                            <th >EV Adoption</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr style={{ backgroundImage: `url(${row1})`}}>
                            <td >Diesel</td>
                            <td style={{ color: '#2a2a2a' }}>Reduced</td>
                            <td style={{ color: '#2a2a2a' }}>Low</td>
                        </tr>
                        <tr style={{ backgroundImage: `url(${row2})`}}>
                            <td >Renewables</td>
                            <td style={{ color: 'green' }}>Improved</td>
                            <td style={{ color: 'green' }}>High</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </Slide>
);

export default Slide4;
