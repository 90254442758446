import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import Slide from '../../Components/RevealComponents/Slide';



const AirPollution = () => {
    const [showHealthAirPollution, setShowHealth] = useState(false);


    return (
        <Slide >
            <section className='h-100'  >
                <h2 style={{ fontWeight: 'bold', fontSize: '30px' }}>Toggle to see health benefits</h2>
                <div className='d-flex justify-content-between align-items-center'>
                    {/* Click to see health benefits */}
                    <Form className='d-flex justify-content-between align-items-center' style={{  fontSize: '30px' }}>
                        <Form.Label htmlFor="custom-switch" className='m-1'>Internal Combustion Engine</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            
                            onChange={() => setShowHealth(!showHealthAirPollution)}
                            checked={showHealthAirPollution || false}
                        />
                        <Form.Label htmlFor="custom-switch" className='m-1'>Zero-Emission Vehicle</Form.Label>
                    </Form>

                    <a className='d-flex justify-content-center align-items-center' href='mhdzevhealthbenefits#/1' style={{ textDecorationLine: 'none' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" /></svg>
                        <div style={{ fontStyle: 'normal', fontSize: '30px', paddingRight: '20px', textAlign: 'left' }}>Back</div>
                    </a>
                </div>
                {!showHealthAirPollution ?
                    <div style={{ backgroundColor: '#262626', padding: '30px' }}>

                        <div className='my-3  w-100' style={{ color: 'white' }}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <h2>Air Pollution</h2>
                            </div>

                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                Medium and heavy-duty vehicles (MHDVs), such as diesel trucks, buses, and construction equipment, are major sources of air pollution. Globally, MHDVs represent about 4% of the on-road fleet. These vehicles emit 73% of on-road nitrogen oxide (NOx) emissions and 60% of particulate matter (PM2.5) emissions, which result in smog and detrimental impacts to human health. (CALSTART, 2020)

                            </div>
                        </div>

                        <div className='my-3 w-100' style={{ color: 'white' }}>
                            <h3>Health Risks:</h3>
                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                <div>

                                    <strong>Respiratory Issues </strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}> Pollutants like NOx and PM2.5 from diesel engines contribute to respiratory diseases. Studies indicate that long-term exposure to PM2.5 is associated with a 4–8% increase in respiratory mortality for every 10 µg/m³ increase in PM2.5 levels. (EPA, 2023)</p>

                                </div>
                                <div>
                                    <strong>Cardiovascular Problems </strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}> Pollutants like NOx and PM2.5 from diesel engines contribute to cardiovascular diseases. Long-term exposure to ambient PM2.5 can increase the risk of cardiovascular diseases, with a 15% increase in the risk of heart attacks and strokes for every 10 µg/m³ increase in PM2.5 levels. (World Health Organization, 2021)</p>

                                </div>
                                <div>
                                    <strong>Cancer </strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>Diesel exhaust is a significant source of air pollution that contains over 40 known cancer-causing substances, and studies show that long-term exposure to diesel exhaust can increase the risk of lung cancer by up to 40%. (International Agency for Research on Cancer, 2012)</p>

                                </div>
                            </div>


                        </div>
                    </div>
                    :
                    <div style={{ backgroundColor: '#276e42', padding: '30px' }}>

                        <div className='my-3 w-100' style={{ color: 'white' }}>

                            <h2>Clean Air </h2>

                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                The adoption of MHDZEVs leads to significant reductions in smog and particulate pollution in urban and rural areas alike. Medium- and heavy-duty zero-emission vehicles (MHDZEVs), such as electric trucks, buses, and hydrogen fuel cell vehicles, produce no tailpipe emissions, eliminating the release of NOx, particulate matter, and other harmful pollutants. (International Council on Clean Transportation, 2022)
                            </div>
                        </div>

                        <div className='my-3 w-100' style={{ color: 'white' }}>
                            <h3>Health Benefits:</h3>
                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                <div>

                                    <strong>Lower Respiratory and Cardiovascular Issues</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}> Reduced air pollution decreases the incidence of asthma, bronchitis, heart disease, and other related conditions. Transitioning to electric vehicles could prevent 120,000 asthma attacks and 2,200 heart attacks annually in the U.S. by 2050. (American Lung Association, 2020)</p>

                                </div>
                                <div>
                                    <strong>Decreased Cancer Risk</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}> Elimination of diesel exhaust helps lower the risk of lung cancer and other pollution-related cancers.</p>

                                </div>
                                <div>
                                    <strong>Fewer Premature Deaths</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>Cleaner air leads to longer life expectancy and improved public health. MHDZEVs help mitigate air pollution, therefore contributing to a reduction in premature deaths. For example, it has been shown that implementing a 12 μg/m³ limit on PM2.5 could reduce global premature mortality by 46%, preventing approximately 1.44 million deaths annually. (Lelieveld et al., 2015)</p>

                                </div>
                            </div>

                        </div>
                    </div>
                }
            </section>

        </Slide >

    )
};

export default AirPollution;
