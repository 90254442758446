import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import globe from '../../images/policyslides/policy-2-globe.png';

const Slide2 = () => {
    return (
        <Slide >
            <section data-prevent-swipe >

                <div style={{ padding: '10px', backgroundColor: '#e6e6e6', zIndex: '999', marginBottom: '20px', translate: '50px 30px' }}>

                    <p style={{ translate: '-30px 5px', textAlign: 'left', fontSize: '45px' }}><strong style={{ backgroundColor: '#276e42', paddingLeft: '30px', paddingRight: '10px', color: 'white', overflow: 'visible', whiteSpace: 'nowrap' }}>Policies and Incentives</strong> play a crucial</p>
                    <p style={{ translate: '-30px 5px', textAlign: 'left', fontSize: '45px', paddingLeft: '30px' }}>role in promoting the adoption of EVs.</p>
                </div>

                <div className='r-stack'>

                    <div data-prevent-swipe className='fragment fade-out' data-fragment-index="0" style={{ zIndex: '-999', textAlign: 'left' }}>
                        <Image src={globe} style={{ zIndex: '-999', position: 'fixed', left: '-100px', bottom: '-100px' }}  />
                       
                        <p className='justify-content-flex-end' style={{ width: '65%', float: 'right', translate: '0px 100px', fontSize: '50px' }}>A study of such measures in 20 countries found that three major factors influence EV market share</p>
                    </div>

                    <div data-prevent-swipe className='fragment fade-in' data-fragment-index="0" style={{ width: '35%', backgroundColor: '#e6e6e6', position: 'absolute', left: '100px', paddingTop: '20px', paddingLeft: '10px', translate: '10px 50px' }} >
                        <div style={{ marginLeft: '10px', textAlign: 'left', fontSize: '35px' }}>Those factors are</div>

                        <div className='align-items-start' >
                            <div style={{ padding: '20px', translate: '60px', marginTop: '10px', marginBottom: '10px', textAlign: 'left' }}>
                                <strong style={{ backgroundColor: '#276e42', padding: '10px', color: 'white', fontSize: '40px', overflow: 'visible', whiteSpace: 'nowrap' }}>Tax Incentives</strong>
                            </div>
                            <div style={{ padding: '20px', translate: '60px', marginTop: '10px', marginBottom: '10px', textAlign: 'left' }}>
                                <strong style={{ backgroundColor: '#276e42', padding: '10px', color: 'white', fontSize: '40px', overflow: 'visible', whiteSpace: 'nowrap' }}>Charger Density</strong>
                            </div>
                            <div style={{ padding: '20px', translate: '60px', marginTop: '10px', marginBottom: '10px', textAlign: 'left' }}>
                                <strong style={{ backgroundColor: '#276e42', padding: '10px', color: 'white', fontSize: '40px', overflow: 'visible', whiteSpace: 'nowrap' }}>Income Level</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Slide>
    );

};

export default Slide2;
