import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

const TitleSlide = () => (
  <Slide >
    <section>
      <div style={{ color: 'white', backgroundColor: '#276e42',  margin: 'auto', width: '70%', textAlign: 'left', paddingLeft: '30px', translate: '-10% 45%' }}>
        <p style={{ fontSize: '70px', padding: '20px' }}><strong>MHDZEV Comparison</strong></p>
      </div>
    </section>
  </Slide>
);

export default TitleSlide;
