import React from 'react';
import TitleSlide from './TitleSlide';
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import Slide5 from './Slide5';
import Slide6 from './Slide6';
// import Slide7 from './Slide7';
// import Slide8 from './Slide8';
// import Slide9 from './Slide9';
import Slide10 from './Slide10';
import Slide11 from './Slide11';


export default [
  <TitleSlide key="1"/>,
  <Slide1 key="2"/>,
  <Slide2 key="3"/>,
  <Slide3 key="4"/>,
  <Slide4 key="5"/>,
  <Slide5 key="6"/>,
  <Slide6 key="7"/>,
 // <Slide7 />,
  // <Slide8 />,
  // <Slide9 />,
  <Slide10 key="8"/>,
  <Slide11 key="9"/>
];
