import React from 'react';
import PropTypes from 'prop-types';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Offcanvas, Container } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import pic1 from '../../images/overview/overview-13-ecocanada.png'

import '../../Themes/dashboard.css';


import ecoImpactBtn from '../../images/eco-impact-btn.jpg'
import overviewBtn from '../../images/overview.jpg'
import healthBenefitsBtn from '../../images/healthbenefits.jpg'
import econFactorBtn from '../../images/econ-factor-btn.jpg'
import mhdzevBtn from '../../images/mhzev-btn.jpg'
import techBtn from '../../images/tech-btn.jpg'
import policyBtn from '../../images/policy-btn.jpg'


const MobileNavBar = ({ title }) => {

    return (
        <div>
            {/* MOBILE */}
            <Navbar expand="lg" className="w-100 d-block d-lg-none bg-body-tertiary " style={{ backgroundColor: '#276e42' }} id="sidebar" >
                <Container fluid  >

                    <Navbar.Brand className="p-3 " style={{ color: 'white' }}>
                        {title}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="offcanvasNavbar-expand" />
                    <Navbar.Offcanvas
                        id="offcanvasNavbar-expand"
                        aria-labelledby="offcanvasNavbarLabel-expand"
                        placement="end"
                    >
                        <Offcanvas.Header closeButton />
                        <Offcanvas.Body>
                            <Nav variant="underline" className="flex-column  " defaultActiveKey="/" >
                            <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${overviewBtn})`, backgroundSize: 'cover' }}>
                                    <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/" style={{ color: 'white', fontSize: '20px' }}>
                                        <span className="material-symbols-outlined">
                                            home
                                        </span>
                                        <span className='mx-2' >Overview</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${techBtn})`, backgroundSize: 'cover' }}>
                                    <Nav.Link className="nav-link d-flex justify-content-center align-items-center" eventKey="link-1" href="/technologyandinnovation" style={{ color: 'white', fontSize: '20px' }}>
                                        Technology and Innovation
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${econFactorBtn})`, backgroundSize: 'cover' }}>
                                    <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/economicfactors" style={{ color: 'white', fontSize: '20px' }}>
                                        Economic Factors
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${ecoImpactBtn})`, backgroundSize: 'cover' }}>
                                    <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/environmentalimpact" style={{ color: 'white', fontSize: '20px' }}>
                                        Environmental Impact
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${healthBenefitsBtn})`, backgroundSize: 'cover' }}>
                                    <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/mhdzevhealthbenefits" style={{ color: 'white', fontSize: '20px' }}>
                                        Health Benefits
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${policyBtn})`, backgroundSize: 'cover' }}>
                                    <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/policiesandincentives" style={{ color: 'white', fontSize: '20px' }}>
                                        Policies and Incentives
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${mhdzevBtn})`, backgroundSize: 'cover' }}>
                                    <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/mhdzevcomparison" style={{ color: 'white', fontSize: '20px' }}>
                                        MHDZEV Comparison
                                    </Nav.Link>
                                </Nav.Item>
                                <div style={{ margin: 'auto' }} className='my-3 py-3 d-flex justify-content-center align-items-center'>

         
<a href="https://eco.ca/educators/indigenous-and-small-business-awareness-for-combating-climate-change-through-adoption-of-zero-emission-medium-and-heavy-vehicles/" style={{ textAlign: 'center' }}>
  <Image className='grow' src={pic1} alt='Rural' height='50%' width='50%' />
</a>
</div>
                            </Nav>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar >
        </div>
    );
};


MobileNavBar.propTypes = {
    title: PropTypes.node,
};

export default MobileNavBar;