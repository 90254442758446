import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

import indiginous from '../../images/policyslides/policy-17-indiginous.png'


const Slide11 = () => (
    <Slide  >
        <div style={{ textAlign: 'left', padding: '20px', fontSize: '50px', fontWeight: 'bold' }}>Indigenous communities have also looked to increase EV adoption</div>
        <section data-auto-animate data-background-image={indiginous} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1}  >
            <div style={{ margin: 'auto' }}>
                <p style={{ textAlign: 'left', width: '90%', margin: 'auto', fontSize: '50px' }}>NRCan is funding Indigenous-led projects that address EV adoption challenges.</p>
            </div>
        </section>
        <section data-auto-animate data-background-image={indiginous} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1}  >
            <div style={{ margin: 'auto' }}>
                <p style={{ textAlign: 'left', width: '90%', margin: 'auto', fontSize: '50px' }}>Indigenous Clean Energy's Charge Up program is providing funding for EV charging stations in Indigenous communities.</p>
            </div>
        </section>
        <section data-auto-animate data-background-image={indiginous} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1}  >
            <div style={{ margin: 'auto' }}>
                <p style={{ textAlign: 'left', width: '90%', margin: 'auto', fontSize: '50px' }}>The Malahat First Nation has also spearheaded a new battery factory on Vancouver Island, which is set to be completed in 2025.</p>
            </div>
        </section>

    </Slide>
);

export default Slide11;

   


