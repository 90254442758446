import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

const TitleSlide = () => (
  <Slide >
    <section>
      <div style={{ color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '70%', textAlign: 'left', paddingLeft: '50px', translate: '-10% 40%' }}>
        <p style={{ fontSize: '90px' }}><strong>Policies and</strong></p>
        <p style={{ fontSize: '90px' }}><strong>Incentives</strong></p>

      </div>
    </section>

  </Slide>
);

export default TitleSlide;
