import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

const TitleSlide = () => (
  <Slide >
    {/* <h2 style={{ color: 'white', backgroundColor: '#276e42', width: '50%', margin: 'auto'  }}></h2> */}
    <section>
      <div style={{ color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '70%', textAlign: 'left', paddingLeft: '30px', translate: '-10% 35%' }}>
        <p style={{ fontSize: '70px' }}><strong>Environmental Impact and Other Benefits</strong></p>
      </div>
    </section>
  </Slide>
);

export default TitleSlide;
