import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import car1 from '../../images/comparisonslides/zevlightningzev5.png'
import car2 from '../../images/comparisonslides/newisuzu.png'

const Class5 = () => (
    <Slide >
        <section>
            <div className='d-flex justify-content-between align-items-center' style={{ fontSize: '30px', color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '100%', textAlign: 'center' }}>
                <p className='p-2'><strong>Class 5: Lightning eMotors ZEV5</strong></p>
                <a className='d-flex justify-content-center align-items-center' href='mhdzevcomparison#/1' style={{ textDecorationLine: 'none' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" /></svg>
                    <div style={{ color: 'white', fontStyle: 'normal', paddingRight: '20px', textAlign: 'left' }}>Back</div>
                </a>
            </div>

            <div style={{ padding: '1px' }} >
                <div className='d-flex justify-content-center align-items-center'>
                    <div style={{ textAlign: 'center', width: '50%' }}>
                        <Image src={car1} style={{ height: '75%', width: '75%' }} alt='Lightning ZEV5' />

                    </div>

                    <div style={{ textAlign: 'center', width: '50%' }}>
                        <Image src={car2} style={{ height: '75%', width: '75%' }} alt='Isuzu NPR' />

                    </div>

                </div>
                <div className='d-flex justify-content-between align-items-start'>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                        <div style={{ fontSize: '25px' }}>Lightning ZEV5</div>
                        <ul >
                            <li style={{ fontSize: '15px' }}><strong>Range: </strong>Up to 240 km (150 miles)</li>
                            <li style={{ fontSize: '15px' }}><strong>Charging options: </strong>Level 2 AC (up to 19.2 kW), and DC Fast Charge (up to 80 kW)</li>
                            <li style={{ fontSize: '15px' }}><strong>Charging Time: </strong>2.5 hours with DC Fast Charge at 80 kW, or 10 hours with AC charging at 19.2 kW</li>
                            <li style={{ fontSize: '15px' }}><strong>Payload Capacity: </strong>10,200 lbs</li>
                            <li style={{ fontSize: '15px' }}><strong>Peak Power: </strong>Up to 355 Horsepower</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>Eligible for up to $75,000 under the iMHZEV Program in Canada, subject to eligibility and location</li>
                            {/* <li style={{ fontSize: '15px' }}><strong>Total Cost: </strong>Not publicly available, but the Lightning ZEV 5 is likely to have a higher cost upfront when compared to the Isuzu NPR. Lower running costs will benefit a consumer who chooses this vehicle however.</li> */}
                        </ul>
                    </div>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                        <div style={{ fontSize: '25px' }}>Isuzu NPR</div>
                        <ul>
                            <li style={{ fontSize: '15px' }}><strong>Fuel Type: </strong>Available with both Gasoline and Diesel engine options</li>
                            <li style={{ fontSize: '15px' }}><strong>Payload Capacity: </strong>Up to 6,796 lbs</li>
                            <li style={{ fontSize: '15px' }}><strong>Peak Power: </strong>297 Horsepower</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>N/A</li>
                            {/* <li style={{ fontSize: '15px' }}><strong>Total Cost: </strong>Around $100,000 to purchase outright, but will have more expensive running and maintenance costs than the lightning ZEV5</li> */}
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    </Slide>
);

export default Class5;
