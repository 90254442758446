import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

const MovieSlide = () => (
    <Slide>
        <section>
            <iframe title="zevai" src="https://www.youtube.com/embed/kztTHWG5c2s?autoplay=1" width="960" height="540" allowFullScreen />
        </section>


    </Slide>
);

export default MovieSlide;
