import React from 'react';
import TitleSlide from './TitleSlide';
import LifeCycleSlide from './LifeCycleSlide';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import Slide5 from './Slide5';
import Slide6 from './Slide6';

export default [
  <TitleSlide key="1"/>,
  <LifeCycleSlide key="2"/>,
  <Slide2 key="3"/>,
  <Slide3 key="4"/>,
  <Slide4 key="5"/>,
  <Slide5 key="6"/>,
  <Slide6 key="7"/>
];
