import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import truck from '../../images/comparisonslides/zevtruck.png'
import van from '../../images/comparisonslides/zevvan.png'
import microbird from '../../images/comparisonslides/zevmicrobird.png'
import lightning from '../../images/comparisonslides/zevlightningzev5.png'
import byd from '../../images/comparisonslides/zevbyd6f.png'
import arboc from '../../images/comparisonslides/zevarbocequess.png'
import battle from '../../images/comparisonslides/zevbattle.png'
import agricultural from '../../images/comparisonslides/zevagriculture.png'

import '../../Themes/dashboard.css';

const HomeSlide = () => {

  return (
    <Slide >
      <section>
        <div style={{ color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '100%', textAlign: 'center' }}>
          <p style={{ fontSize: '40px', padding: '10px' }}><strong>MHDZEV Comparison</strong></p>
        </div>

        <div style={{ paddingTop: '30px' }}>
          <p style={{ fontSize: '20px' }}>Below is a selection of examples chosen to illustrate the wide variety of vehicle classes that ZEVs cover.</p>
          <p style={{ fontSize: '20px' }}>
            Most of these examples are part of the <a style={{ color: '#276e42', fontStyle: 'normal', fontWeight: 'bold' }} href='https://tc.canada.ca/en/road-transportation/innovative-technologies/zero-emission-vehicles/medium-heavy-duty-zero-emission-vehicles/eligible-vehicles'>iMHZEV Program</a>,
            providing incentives of up to $200,000.
          </p>
        </div>
        <div style={{ padding: '50px' }} >
          <div className='d-flex justify-content-evenly align-items-center'>
            <a className='simple-nav' href="/mhdzevcomparison#/2">
              <Image fluid src={truck} alt='Chevrolet Silverado EV' />

            </a>

            <a className='simple-nav' href="/mhdzevcomparison#/3">
              <Image fluid src={van} alt='Lightning ZEV3' />

            </a>

            <a className='simple-nav' href="/mhdzevcomparison#/4" style={{ height: '90%', width: '90%' }}>
              <Image fluid src={microbird} alt='Microbird Commercial G5' />

            </a>

            <a className='simple-nav' href="/mhdzevcomparison#/5">
              <Image fluid src={lightning} alt='Lightning ZEV5' />

            </a>
          </div>

          <div className='d-flex justify-content-evenly align-items-center'>
            <a className='simple-nav' href="/mhdzevcomparison#/6" style={{ height: '80%', width: '80%' }}>
              <Image fluid src={byd} alt='BYD 6F' />

            </a>

            <a className='simple-nav' href="/mhdzevcomparison#/7" style={{ height: '65%', width: '65%' }}>
              <Image fluid src={arboc} alt='Arboc Equess Charge' />

            </a>

            <a className='simple-nav' href="/mhdzevcomparison#/8" style={{ height: '80%', width: '80%' }}>
              <Image fluid src={battle} alt='Nikola Tre' />

            </a>

            <a className='simple-nav' href="/mhdzevcomparison#/9" style={{ height: '70%', width: '70%' }}>
              <Image fluid src={agricultural} alt='Fendt e100 V Vario' />
            </a>
          </div>

        </div>
      </section>
    </Slide>
  )
};

export default HomeSlide;
