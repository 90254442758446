import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import car1 from '../../images/comparisonslides/zevagriculture.png'
import car2 from '../../images/comparisonslides/tractor2.png'

const Class9 = () => (
    <Slide >
        <section>
            <div className='d-flex justify-content-between align-items-center' style={{ fontSize: '30px', color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '100%', textAlign: 'center' }}>
                <p className='p-2'><strong>EV's in Agriculture: A Comparison</strong></p>
                <a className='d-flex justify-content-center align-items-center' href='mhdzevcomparison#/1' style={{ textDecorationLine: 'none'}}>
                    <svg   xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" /></svg>
                    <div style={{ color: 'white', fontStyle: 'normal', paddingRight: '20px', textAlign: 'left' }}>Back</div>
                </a>
            </div>

            <div style={{ padding: '1px' }} >
                <div className='d-flex justify-content-center align-items-center'>
                    <div style={{ textAlign: 'center', margin: '20px', width: '50%' }}>
                        <Image src={car1} style={{ height: '70%', width: '70%' }} alt='Fendt e100 V Vario' />

                    </div>

                    <div style={{ textAlign: 'center', margin: '20px', width: '50%' }}>
                        <Image src={car2} style={{ height: '75%', width: '75%' }} alt='Fendt 211 Vario' />

                    </div>

                </div>
                <div className='d-flex justify-content-between align-items-start'>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                    <div style={{ fontSize: '25px' }}>Fendt e100 V Vario</div>
                        <ul>
                            <li style={{ fontSize: '15px' }}><strong>Range: </strong>Dependent on task, 4-5 hours of work</li>
                            <li style={{ fontSize: '15px' }}><strong>Charging: </strong>Compatible with a Level 2 charging, using a 400V/22kW charger. It is also compatible with Level 3 charging, utilising DC</li>
                            <li style={{ fontSize: '15px' }}><strong>Power: </strong>Up to 124 Horsepower</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>N/A</li>
                            <li style={{ fontSize: '15px' }}><strong>Cost Analysis: </strong>Upfront cost is relatively higher than counterpart due to advanced battery technology. However, operating costs are significantly lower, with fewer mechanical parts requiring service.</li>
                        </ul>
                    </div>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                    <div style={{ fontSize: '25px' }}>Fendt 211 Vario</div>
                        <ul>
                            <li style={{ fontSize: '15px' }}><strong>Range: </strong>Can work a full day on one tank of fuel</li>
                            <li style={{ fontSize: '15px' }}><strong>Fuel: </strong>Diesel</li>
                            <li style={{ fontSize: '15px' }}><strong>Power: </strong>Up to 124 Horsepower</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>N/A</li>
                            <li style={{ fontSize: '15px' }}><strong>Total Cost: </strong>Lower upfront cost compared  to its zero emissions counterpart, but will have more expensive 
                            operating costs as a result of fuel and engine maintenance.</li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    </Slide>
);

export default Class9;
