import React from 'react';
// import Figure from 'react-bootstrap/Figure';
import Slide from '../../Components/RevealComponents/Slide';

import books from '../../images/policyslides/books.png'

const Slide1 = () => (
    <Slide >
        <section data-prevent-swipe >
            <div data-prevent-swipe style={{ translate: '0px -100px'}}>
                <img src={books} alt='Books' height="80%" width="80%" style={{ zIndex: '-999' }} />
                <div style={{ position: 'relative', top: '-170px', fontSize: '50px', textAlign: 'left', zIndex: '1' }}> 
                    <p style={{ fontSize: '50px' }}><strong style={{ backgroundColor: '#276e42', fontSize: '50px', paddingLeft: '30px', paddingRight: '10px', color: 'white' }}>Policies and Incentives</strong> play a crucial</p>
                    <p style={{ fontSize: '50px', paddingLeft: '30px' }}>role in promoting the adoption of EVs.</p> 
                </div>
                <div  style={{ backgroundColor: '#e6e6e6', height: '180px', width:'1220px', zIndex: '0', position: 'relative', top: '-350px', left: '20px' }} />

                {/* <Figure>
                        <Figure.Image
                            width="80%"
                            height="80%"
                            alt="Books"
                            src={books}
                        />
                        <Figure.Caption style={{ backgroundColor: '#e6e6e6', color: 'black', fontSize: '50px', textAlign: 'left', padding: '10px', zIndex: '999', position: 'relative', top:'-200px',  lineHeight: '90px' }}>
                            <strong style={{ backgroundColor: '#276e42', padding: '5px', color: 'white' }}>Policies and Incentives</strong> play a crucial role in promoting the adoption of EVs.
                        </Figure.Caption>
                    </Figure> */}
            </div>
        </section>
    </Slide>
);

export default Slide1;
