import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';


const Slide2 = () => (
    <Slide >
        <section data-auto-animate data-auto-animate-id="one" data-transition="fade" >
            <div style={{ margin: 'auto' }}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '90%', color: '#276e42' }} ><strong>Why ZEVs Matter for Medium and Heavy-Duty Vehicles</strong></div>
                

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px' }}>
                    Adopting ZEVs in medium and heavy-duty applications is crucial for several reasons.
                </p>
            </div>
        </section>
        <section data-auto-animate data-auto-animate-id="one" data-transition="fade">
            <div style={{ margin: 'auto' }}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '90%', color: '#276e42' }} ><strong>Why ZEVs Matter for Medium and Heavy-Duty Vehicles</strong></div>

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px' }}>
                    Adopting ZEVs in medium and heavy-duty applications is crucial for several reasons.
                </p>

                <div style={{ translate: '18px' }}>
                    <div style={{ backgroundColor: '#e6e6e6', width: '100px', height: '220px', position: 'absolute', zIndex: '-999', bottom: '-1%', left: '-2%' }} />
                    <div className='d-flex align-items-center' style={{ translate: '-1%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M210-163q-49-49-74.5-111.74Q110-337.48 110-403q0-72 26-138t73-115q48-50 110-77t135-37q73-10 157.5-8t178.5 9q10 105 15.5 193T803-414q-8 74-32.5 135.5T696-167q-47 49-108.8 76T461-64q-71 0-133.5-22T210-163Zm132-47q29 15 59 22t59.9 6q41.99-1 80.05-19Q579-219 614-252q37-36 53.5-87T689-446.5q5-56.5 1-112.5t-9-99q-71-8-132-5t-110 14q-49 11-84.5 29.5T297-577q-29 32-49 77t-20 87.9q0 40.1 11.5 78.1 11.5 38 40.5 72 32-74 92-133.5T528-506q-74 69-117.5 143T342-210Zm0 0Zm0 0Z" /></svg>
                        <p style={{ textAlign: 'left', fontSize: '30px' }}><strong>Environmental Impact</strong></p>
                    </div>

                    <p style={{ textAlign: 'left', fontSize: '30px', width: '85%' }}>
                        ZEVs help reduce greenhouse gas emissions and improve air quality, which is espcially important for communities where air pollution is a concern.
                    </p>
                </div>

            </div>
        </section>
        <section data-auto-animate data-auto-animate-id="one" data-transition="fade">
            <div style={{  margin: 'auto' }}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '90%', color: '#276e42', translate: '40px' }} ><strong>Why ZEVs Matter for Medium and Heavy-Duty Vehicles</strong></div>

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px' }}>
                    Adopting ZEVs in medium and heavy-duty applications is crucial for several reasons.
                </p>

                <div style={{ translate: '18px' }}>
                    <div style={{ backgroundColor: '#e6e6e6', width: '100px', height: '280px', position: 'absolute', zIndex: '-999', bottom: '-1%', left: '-2%' }} />
                    <div className='d-flex align-items-center' style={{ translate: '-1%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M210-163q-49-49-74.5-111.74Q110-337.48 110-403q0-72 26-138t73-115q48-50 110-77t135-37q73-10 157.5-8t178.5 9q10 105 15.5 193T803-414q-8 74-32.5 135.5T696-167q-47 49-108.8 76T461-64q-71 0-133.5-22T210-163Zm132-47q29 15 59 22t59.9 6q41.99-1 80.05-19Q579-219 614-252q37-36 53.5-87T689-446.5q5-56.5 1-112.5t-9-99q-71-8-132-5t-110 14q-49 11-84.5 29.5T297-577q-29 32-49 77t-20 87.9q0 40.1 11.5 78.1 11.5 38 40.5 72 32-74 92-133.5T528-506q-74 69-117.5 143T342-210Zm0 0Zm0 0Z" /></svg>
                        <p style={{ textAlign: 'left', fontSize: '30px' }}><strong>Environmental Impact</strong></p>
                    </div>
                    <div className='d-flex align-items-center' style={{ translate: '-1%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M447-109v-86q-63-11-101.5-48.5T292-339l78-30q15 52 47 73.5t72 21.5q45 0 71.5-20.5T587-350q0-39-28-62.5T456-459q-70-21-106.5-62.5T313-623q0-52 33-92.5T447-766v-85h71v85q47 9 82.5 35.5T654-659l-76 31q-14-30-37.5-45.5T481-689q-41 0-63 16.5T396-626q0 32 24 53.5T529-525q70 23 105.5 64.5T670-354q0 66-38.5 107T518-194v85h-71Z" /></svg>
                        <p style={{ textAlign: 'left', fontSize: '30px' }}><strong>Economic Benefits</strong></p>
                    </div>

                    <p style={{ textAlign: 'left', fontSize: '30px', width: '85%' }}>
                        Over time, ZEVs can lower fuel and maintenance costs. Government incentives and rebates can further offset initial costs, making them more affordable.
                    </p>
                </div>
            </div>
        </section>

        <section data-auto-animate data-auto-animate-id="one" data-transition="fade">
            <div style={{  margin: 'auto'}}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '90%', color: '#276e42', translate: '40px' }} ><strong>Why ZEVs Matter for Medium and Heavy-Duty Vehicles</strong></div>

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px' }}>
                    Adopting ZEVs in medium and heavy-duty applications is crucial for several reasons.
                </p>

                <div style={{ translate: '18px' }}>
                    <div style={{ backgroundColor: '#e6e6e6', width: '100px', height: '350px', position: 'absolute', zIndex: '-999', bottom: '-1%', left: '-2%' }} />
                    <div className='d-flex align-items-center' style={{ translate: '-1%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M210-163q-49-49-74.5-111.74Q110-337.48 110-403q0-72 26-138t73-115q48-50 110-77t135-37q73-10 157.5-8t178.5 9q10 105 15.5 193T803-414q-8 74-32.5 135.5T696-167q-47 49-108.8 76T461-64q-71 0-133.5-22T210-163Zm132-47q29 15 59 22t59.9 6q41.99-1 80.05-19Q579-219 614-252q37-36 53.5-87T689-446.5q5-56.5 1-112.5t-9-99q-71-8-132-5t-110 14q-49 11-84.5 29.5T297-577q-29 32-49 77t-20 87.9q0 40.1 11.5 78.1 11.5 38 40.5 72 32-74 92-133.5T528-506q-74 69-117.5 143T342-210Zm0 0Zm0 0Z" /></svg>
                        <p style={{ textAlign: 'left', fontSize: '30px' }}><strong>Environmental Impact</strong></p>
                    </div>
                    <div className='d-flex align-items-center' style={{ translate: '-1%'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M447-109v-86q-63-11-101.5-48.5T292-339l78-30q15 52 47 73.5t72 21.5q45 0 71.5-20.5T587-350q0-39-28-62.5T456-459q-70-21-106.5-62.5T313-623q0-52 33-92.5T447-766v-85h71v85q47 9 82.5 35.5T654-659l-76 31q-14-30-37.5-45.5T481-689q-41 0-63 16.5T396-626q0 32 24 53.5T529-525q70 23 105.5 64.5T670-354q0 66-38.5 107T518-194v85h-71Z" /></svg>
                        <p style={{ textAlign: 'left', fontSize: '30px' }}><strong>Economic Benefits</strong></p>
                    </div>
                    <div className='d-flex align-items-center' style={{translate: '-1%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="m465-233 172-297H501v-196L322-430h143v197Zm15.14 178Q392-55 314.51-88.08q-77.48-33.09-135.41-91.02-57.93-57.93-91.02-135.27Q55-391.72 55-479.86 55-569 88.08-646.49q33.09-77.48 90.86-134.97 57.77-57.48 135.19-91.01Q391.56-906 479.78-906q89.22 0 166.83 33.45 77.6 33.46 135.01 90.81t90.89 134.87Q906-569.34 906-480q0 88.28-33.53 165.75t-91.01 135.28q-57.49 57.8-134.83 90.89Q569.28-55 480.14-55Zm-.14-94q138 0 234.5-96.37T811-480q0-138-96.5-234.5t-235-96.5q-137.5 0-234 96.5t-96.5 235q0 137.5 96.37 234T480-149Zm0-331Z" /></svg>
                        <p style={{ textAlign: 'left', fontSize: '30px' }}><strong>Energy Independence</strong></p>
                    </div>

                    <p style={{ textAlign: 'left', fontSize: '30px', width: '80%' }}>
                        By using electricity or hydrogen, ZEVs reduce reliance on imported oil, contributing to energy security and stability.
                    </p>
                </div>
            </div>
        </section>

        <section data-auto-animate data-auto-animate-id="one" data-transition="fade">
            <div style={{  margin: 'auto'}}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '90%', color: '#276e42', translate: '40px' }} ><strong>Why ZEVs Matter for Medium and Heavy-Duty Vehicles</strong></div>

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px' }}>
                    Adopting ZEVs in medium and heavy-duty applications is crucial for several reasons.
                </p>

                <div style={{ translate: '18px' }}>
                    <div style={{ backgroundColor: '#e6e6e6', width: '100px', height: '370px', position: 'absolute', zIndex: '-999', bottom: '-1%', left: '-2%' }} />
                    <div className='d-flex align-items-center' style={{ translate: '-1%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M210-163q-49-49-74.5-111.74Q110-337.48 110-403q0-72 26-138t73-115q48-50 110-77t135-37q73-10 157.5-8t178.5 9q10 105 15.5 193T803-414q-8 74-32.5 135.5T696-167q-47 49-108.8 76T461-64q-71 0-133.5-22T210-163Zm132-47q29 15 59 22t59.9 6q41.99-1 80.05-19Q579-219 614-252q37-36 53.5-87T689-446.5q5-56.5 1-112.5t-9-99q-71-8-132-5t-110 14q-49 11-84.5 29.5T297-577q-29 32-49 77t-20 87.9q0 40.1 11.5 78.1 11.5 38 40.5 72 32-74 92-133.5T528-506q-74 69-117.5 143T342-210Zm0 0Zm0 0Z" /></svg>
                        <p style={{ textAlign: 'left', fontSize: '30px' }}><strong>Environmental Impact</strong></p>
                    </div>
                    <div className='d-flex align-items-center' style={{ translate: '-1%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M447-109v-86q-63-11-101.5-48.5T292-339l78-30q15 52 47 73.5t72 21.5q45 0 71.5-20.5T587-350q0-39-28-62.5T456-459q-70-21-106.5-62.5T313-623q0-52 33-92.5T447-766v-85h71v85q47 9 82.5 35.5T654-659l-76 31q-14-30-37.5-45.5T481-689q-41 0-63 16.5T396-626q0 32 24 53.5T529-525q70 23 105.5 64.5T670-354q0 66-38.5 107T518-194v85h-71Z" /></svg>
                        <p style={{ textAlign: 'left', fontSize: '30px' }}><strong>Economic Benefits</strong></p>
                    </div>
                    <div className='d-flex align-items-center' style={{ translate: '-1%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="m465-233 172-297H501v-196L322-430h143v197Zm15.14 178Q392-55 314.51-88.08q-77.48-33.09-135.41-91.02-57.93-57.93-91.02-135.27Q55-391.72 55-479.86 55-569 88.08-646.49q33.09-77.48 90.86-134.97 57.77-57.48 135.19-91.01Q391.56-906 479.78-906q89.22 0 166.83 33.45 77.6 33.46 135.01 90.81t90.89 134.87Q906-569.34 906-480q0 88.28-33.53 165.75t-91.01 135.28q-57.49 57.8-134.83 90.89Q569.28-55 480.14-55Zm-.14-94q138 0 234.5-96.37T811-480q0-138-96.5-234.5t-235-96.5q-137.5 0-234 96.5t-96.5 235q0 137.5 96.37 234T480-149Zm0-331Z" /></svg>
                        <p style={{ textAlign: 'left', fontSize: '30px' }}><strong>Energy Independence</strong></p>
                    </div>
                    <div className='d-flex align-items-center' style={{ translate: '-1%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M150-103v-128h144l-93-317q-45-14-71.5-47.5T103-684q0-56 41-97.5t97-41.5q53 0 89 29t49 80h109v-59q0-17 12-29t29-12q10 0 17.5 7t8.5 17l68-65q11-10 26.5-13.5T681-865l158 76q14 7 19 21t-3 29q-7 13-21 17t-27-4l-147-72-89 90v42l89 95 147-71q15-7 27.5-4t20.5 16q8 15 3 30t-20 22l-150 71q-19 10-35.5 9T623-512l-68-65q-1 14-8 19t-18 5q-17 0-29-12t-12-29v-60H377q-2 17-12.5 38.5T342-586l183 355h184v128H150Zm91-525q24 0 40-16t16-40q0-24-16-40t-40-16q-24 0-40 16t-16 40q0 24 16 40t40 16Zm120 397h97L289-562q-5 3-12.5 5.5T265-553l96 322Zm97 0Z" /></svg>
                        <p style={{ textAlign: 'left', fontSize: '30px' }}><strong>Technological Advancement</strong></p>
                    </div>

                    <p style={{ textAlign: 'left', fontSize: '30px', width: '80%' }}>
                        Investing in ZEVs drives innovation, supporting the growth of new industries and job creation.
                    </p>
                </div>
            </div>
        </section>
    </Slide>
);

export default Slide2;

// , lineHeight: '40px'