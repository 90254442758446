import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import truck from '../../images/comparisonslides/zevtruck.png'
import truck2 from '../../images/comparisonslides/ford150.png'

const Class2B = () => (
    <Slide >
        <section>
            <div className='d-flex justify-content-between align-items-center' style={{ fontSize: '30px', color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '100%', textAlign: 'center' }}>
                <p className='p-2'><strong>Class 2B: Chevrolet Silverado EV</strong></p>
                <a className='d-flex justify-content-center align-items-center' href='mhdzevcomparison#/1' style={{ textDecorationLine: 'none' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" /></svg>
                    <div style={{ color: 'white', fontStyle: 'normal', paddingRight: '20px', textAlign: 'left' }}>Back</div>
                </a>
            </div>

            <div style={{ padding: '1px' }} >
                <div className='d-flex justify-content-between align-items-center'>
                    <div style={{ textAlign: 'center', width: '50%' }}>
                        <Image src={truck} alt='Chevrolet Silverado EV' style={{ height: '75%', width: '75%' }} />

                    </div>

                    <div style={{ textAlign: 'center', width: '50%' }}>
                        <Image src={truck2} alt='Chevrolet Silverado HD' style={{ height: '75%', width: '75%' }}  />

                    </div>

                </div>
                <div className='d-flex justify-content-between align-items-start'>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                        <div style={{ fontSize: '25px' }}>Chevrolet Silverado EV </div>
                        <ul >
                            <li style={{ fontSize: '15px' }}><strong>Range: </strong>Up to 724 km (With 4WT Model)</li>
                            <li style={{ fontSize: '15px' }}><strong>Charging: </strong>Compatible with Level 1 and Level 2 chargers, up to 19.2 Kw of output</li>
                            {/* <li style={{ fontSize: '15px' }}><strong>Charging Cost: </strong>$46 when using Level 1 or Level 2 chargers</li> */}
                            <li style={{ fontSize: '15px' }}><strong>Towing Capacity: </strong>Up to 10,000 lbs (with the Extended Range Battery)</li>
                            <li style={{ fontSize: '15px' }}><strong>Power: </strong>Up to 754 Horsepower</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>Up to $5,000</li>
                            <li style={{ fontSize: '15px' }}><strong>Total Cost: </strong>Start at $78,033 ($73,033 with rebate)</li>
                        </ul>
                    </div>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                        <div style={{ fontSize: '25px' }}>Chevrolet Silverado HD</div>
                        <ul>
                            <li style={{ fontSize: '15px' }}><strong>Fuel Type: </strong>Available with both Gasoline and Diesel engine options</li>
                            <li style={{ fontSize: '15px' }}><strong>Towing Capacity: </strong>Up to 36,000 lbs (with Duramax® 6.6L Turbo-Diesel V8)</li>
                            <li style={{ fontSize: '15px' }}><strong>Power: </strong>Up to 470 Horsepower</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>N/A</li>
                            <li style={{ fontSize: '15px' }}><strong>Total Cost: </strong>Starts from $61,833</li>
                        </ul>
                    </div>
                </div>

            </div>

        </section>


    </Slide>
);

export default Class2B;
