import React from 'react';
import Figure from 'react-bootstrap/Figure';
import Slide from '../../Components/RevealComponents/Slide';

import graph1 from '../../images/econfactorslides/economic-5-battery.png'
import graph2 from '../../images/econfactorslides/economic-5-plug.png'


const Slide2 = () => {

    return (
        <Slide transition='none'>
            <section data-transition='none'>
                <div style={{ margin: 'auto' }}>
                    <div style={{ fontSize: '50px', textAlign: 'left', lineHeight: '80px', width: '70%', margin: 'auto' }}>In 2023, <strong style={{ color: 'white', backgroundColor: '#2a2a2a', fontSize: '60px', padding: '10px' }}>Canada</strong> saw ZEV sales at 10.8% of total market share</div>

                    <div className='d-flex' >

                        <div className='align-content-center fragment fade-up' data-fragment-index="0" style={{ backgroundColor: '#276e42', width: '50%', margin: '20px' }} >
                            <Figure>
                                <Figure.Image src={graph1} alt='Up-Front Graph' />
                                <Figure.Caption style={{ color: 'white', fontSize: '40px', width: '70%', margin:'auto' }}>8.1% battery electric vehicles</Figure.Caption>
                            </Figure>
                        </div>

                        <div className='align-content-center fragment fade-up' data-fragment-index="1" style={{ backgroundColor: '#276e42', width: '50%', margin: '20px' }} >
                            <Figure>
                                <Figure.Image src={graph2} alt='Lifetime Graph' />
                                <Figure.Caption style={{ color: 'white', fontSize: '40px', width: '70%', margin:'auto' }}>2.6% plug-in hybrid vehicles</Figure.Caption>
                            </Figure>
                        </div>

                    </div>
                </div>

            </section>

        </Slide>
    );
};

export default Slide2;
