import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';

import Slide from '../../Components/RevealComponents/Slide';

import pic from '../../images/techinnoslides/tech-5-lithiummining.png'
import pic2 from '../../images/techinnoslides/tech-6-research.png'
import pic3 from '../../images/techinnoslides/tech-7-salt.png'
import pic4 from '../../images/techinnoslides/tech-8-charging.png'
import pic5 from '../../images/techinnoslides/tech-12-autonomousdriving.png'
import pic6 from '../../images/techinnoslides/tech-13-v2grid.png'

const popover = (
    <Popover >
        <Popover.Body>
            Level 4 chargers utilize high-power technology to charge electric vehicles at speeds comparable to or faster than filling up a traditional gasoline vehicle, enabling lightning-fast charging times in just minutes.
        </Popover.Body>
    </Popover>
);

const Slide2 = () => {
    const [value, setValue] = useState("");

    // Similar to componentDidMount and componentDidUpdate:
    function handleClick(v) {
        setValue(v);
    }
    return (
        <Slide >
            <section data-auto-animate style={{ marign: 'auto' }}>
                <div style={{ textAlign: 'left', margin: '50px' }}><strong style={{ fontSize: '50px', color: 'white', backgroundColor: '#276e42', padding: '20px' }} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF" style={{ margin: 'auto' }}><path d="M342.26-145.09Zm-39.61 79.22q-17.27 0-28.72-11.45t-11.45-28.16v-685.04q0-16.64 11.45-28.41t28.72-11.77h87.74v-64h179.22v64h88.3q16.71 0 28.16 11.77t11.45 28.41V-466q-20.52-2.26-40.61-.54-20.08 1.71-39.17 7.84v-292.21H342.26v605.82h133.35q10.13 23.22 24.39 43.03 14.26 19.8 33.35 36.19h-230.7Zm345 0v-127.26H524.83v-79.78h122.82v-122.26h79.22v122.26H854.7v79.78H726.87v127.26h-79.22Z" /></svg>
                    Batteries</strong>
                </div>
                <p style={{ textAlign: 'left', width: '75%', margin: 'auto' }}><strong style={{ color: '#276e42' }}>Lithium-Ion</strong> batteries are the current leader in Electric Vehicles due to major improvements over previous battery technologies, including: </p>
                <figure style={{ width: '50%', margin: 'auto' }}>
                    <figcaption style={{ translate: '-40px', color: '#276e42', textAlign: 'center', fontSize: '30px' }}><strong>Lithium-Ion</strong></figcaption>
                    <div style={{ textAlign: 'left' }}>
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                            Energy Density</p>
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                            Efficiency</p>
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                            Lifespan
                        </p>
                        <div style={{ height: '220px', width: '90px', backgroundColor: '#e6e6e6', position: 'relative', left: '-20px', top: '-200px', zIndex: '-999' }} />
                    </div>
                </figure>

            </section>

            <section data-auto-animate style={{ marign: 'auto' }}>
                <div style={{ textAlign: 'left', margin: '50px' }}><strong style={{ fontSize: '50px', color: 'white', backgroundColor: '#276e42', padding: '20px' }} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF" style={{ margin: 'auto' }}><path d="M342.26-145.09Zm-39.61 79.22q-17.27 0-28.72-11.45t-11.45-28.16v-685.04q0-16.64 11.45-28.41t28.72-11.77h87.74v-64h179.22v64h88.3q16.71 0 28.16 11.77t11.45 28.41V-466q-20.52-2.26-40.61-.54-20.08 1.71-39.17 7.84v-292.21H342.26v605.82h133.35q10.13 23.22 24.39 43.03 14.26 19.8 33.35 36.19h-230.7Zm345 0v-127.26H524.83v-79.78h122.82v-122.26h79.22v122.26H854.7v79.78H726.87v127.26h-79.22Z" /></svg>
                    Batteries</strong>
                </div>
                <p style={{ textAlign: 'left', width: '75%', margin: 'auto' }}><strong style={{ color: '#276e42' }}>Solid State</strong> are an emerging technology poised to improve upon Lithium-Ion, using a solid electorlyte instead of a liquid one. </p>
                <div className='d-flex' style={{ paddingTop: '30px' }}>
                    <figure style={{ width: '50%' }}>
                        <figcaption style={{ translate: '-40px', color: '#276e42', textAlign: 'center', fontSize: '30px' }}><strong>Lithium-Ion</strong></figcaption>
                        <div style={{ textAlign: 'left' }}>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                                Energy Density</p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                                Efficiency</p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                                Lifespan
                            </p>
                            <div style={{ height: '220px', width: '90px', backgroundColor: '#e6e6e6', position: 'relative', left: '-20px', top: '-200px', zIndex: '-999' }} />
                        </div>
                    </figure>

                    <figure style={{ width: '50%' }}>
                        <figcaption style={{ translate: '-40px', color: '#276e42', textAlign: 'center', fontSize: '30px' }}><strong>Solid-State</strong></figcaption>

                        <div style={{ textAlign: 'left' }}>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                                Improved Density</p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                                Less Flammable</p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#006f44"><path d="M427-427H180.78v-106H427v-246.22h106V-533h246.22v106H533v246.22H427V-427Z" /></svg>
                                Faster Charging
                            </p>
                            <div style={{ height: '220px', width: '90px', backgroundColor: '#e6e6e6', position: 'relative', left: '-20px', top: '-200px', zIndex: '-999' }} />
                        </div>
                    </figure>

                </div>
            </section>

            <section data-auto-animate data-background-image={pic} data-background-size="auto 50%" data-background-position="bottom" data-background-repeat="no repeat" data-background-opacity={1}   >
                <div style={{ textAlign: 'left', margin: '50px' }}><strong style={{ fontSize: '50px', color: 'white', backgroundColor: '#276e42', padding: '20px' }} ><svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF" style={{ margin: 'auto' }}><path d="M342.26-145.09Zm-39.61 79.22q-17.27 0-28.72-11.45t-11.45-28.16v-685.04q0-16.64 11.45-28.41t28.72-11.77h87.74v-64h179.22v64h88.3q16.71 0 28.16 11.77t11.45 28.41V-466q-20.52-2.26-40.61-.54-20.08 1.71-39.17 7.84v-292.21H342.26v605.82h133.35q10.13 23.22 24.39 43.03 14.26 19.8 33.35 36.19h-230.7Zm345 0v-127.26H524.83v-79.78h122.82v-122.26h79.22v122.26H854.7v79.78H726.87v127.26h-79.22Z" /></svg>
                    Batteries</strong></div>

                <div >
                    <div style={{ backgroundColor: '#e6e6e6', color: '#2a2a2a', fontSize: '40px', padding: '10px' }}>
                        <strong>Lithium-Ion + Solid-State</strong>
                    </div>

                    <div style={{ backgroundColor: '#2a2a2a', color: 'white', fontSize: '40px', padding: '10px', width: '95%', margin: 'auto' }}>
                        <strong className='px-3'>Challenges</strong>
                        <div className='d-flex'>
                            <div style={{ width: '50%', textAlign: 'left' }} className='align-self-start'>

                                <p className='px-3' style={{ fontSize: '30px' }}>- Still requires pollution-intensive Lithium mining</p>
                            </div>


                            <div style={{ width: '50%', textAlign: 'left' }} className='align-self-start'>
                                <p className='px-3' style={{ fontSize: '30px' }}>- Challenges related to manufacturing, degradtion, and scalability</p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <section data-auto-animate data-background-image={pic2} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1}   >
                <div style={{ textAlign: 'left', margin: '50px' }}><strong style={{ fontSize: '50px', color: 'white', backgroundColor: '#276e42', padding: '20px' }} ><svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF" style={{ margin: 'auto' }}><path d="M342.26-145.09Zm-39.61 79.22q-17.27 0-28.72-11.45t-11.45-28.16v-685.04q0-16.64 11.45-28.41t28.72-11.77h87.74v-64h179.22v64h88.3q16.71 0 28.16 11.77t11.45 28.41V-466q-20.52-2.26-40.61-.54-20.08 1.71-39.17 7.84v-292.21H342.26v605.82h133.35q10.13 23.22 24.39 43.03 14.26 19.8 33.35 36.19h-230.7Zm345 0v-127.26H524.83v-79.78h122.82v-122.26h79.22v122.26H854.7v79.78H726.87v127.26h-79.22Z" /></svg>
                    Batteries</strong></div>

                <div style={{ textAlign: 'left', margin: '50px' }}>
                    <p><strong style={{ color: '#276e42' }}>Sodium Batteries</strong> are being researched as an alternative to lithium-ion batteries for EVs.</p>
                </div>

            </section>

            <section data-auto-animate data-background-image={pic3} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1}   >
                <div style={{ textAlign: 'left', margin: '50px' }}><strong style={{ fontSize: '50px', color: 'white', backgroundColor: '#276e42', padding: '20px' }} ><svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF" style={{ margin: 'auto' }}><path d="M342.26-145.09Zm-39.61 79.22q-17.27 0-28.72-11.45t-11.45-28.16v-685.04q0-16.64 11.45-28.41t28.72-11.77h87.74v-64h179.22v64h88.3q16.71 0 28.16 11.77t11.45 28.41V-466q-20.52-2.26-40.61-.54-20.08 1.71-39.17 7.84v-292.21H342.26v605.82h133.35q10.13 23.22 24.39 43.03 14.26 19.8 33.35 36.19h-230.7Zm345 0v-127.26H524.83v-79.78h122.82v-122.26h79.22v122.26H854.7v79.78H726.87v127.26h-79.22Z" /></svg>
                    Batteries</strong></div>


                <div style={{ textAlign: 'left', margin: '50px' }}>
                    <p><strong style={{ color: '#276e42' }}>Sodium Batteries</strong> </p>
                    <p>Sodium is more abundant and widely distributed compared to lithium, which makes it a more sustainable and potentially cheaper raw material for battery production.</p>
                </div>
            </section>

            <section className='h-100 m-auto' data-auto-animate data-background-image={pic3} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1}>
                <div className='r-stack '>
                    <div >
                        <div style={{ textAlign: 'left', width: '95%' }}>
                            <p style={{ backgroundColor: '#276e42', color: 'white', padding: '25px', fontSize: '30px' }} >What is the primary difference between lithium-ion batteries and solid-state batteries?</p>
                        </div>

                        <div className='r-stack '>
                            {
                                value === "" ? <div style={{ margin: 'auto', textAlign: 'left' }}>
                                    <Form className="m-2 p-2" onChange={(e) => handleClick(e.target.id)} >

                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="1" label="Lithium-ion batteries use a liquid electrolyte, while solid-state batteries use a solid electrolyte." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="2" label="Lithium-ion batteries are more energy-dense than solid-state batteries." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="3" label="Solid-state batteries are more affordable to manufacture compared to lithium-ion batteries." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px', }} type='radio' id="4" label="Lithium-ion batteries have a longer lifespan compared to solid-state batteries." name="group1" />
                                    </Form>


                                </div> : null
                            }

                            {
                                value === "1" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Correct!</h2>
                                    <p>Lithium-ion batteries use a liquid electrolyte, while solid-state batteries use a solid electrolyte.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "2" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Lithium-ion batteries use a liquid electrolyte, while solid-state batteries use a solid electrolyte.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "3" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Lithium-ion batteries use a liquid electrolyte, while solid-state batteries use a solid electrolyte.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "4" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Lithium-ion batteries use a liquid electrolyte, while solid-state batteries use a solid electrolyte.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                        </div>
                    </div>
                </div>
            </section>

            <section data-auto-animate data-background-image={pic4} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1}   >
                <div style={{ textAlign: 'left', margin: '50px' }}><strong style={{ fontSize: '50px', color: 'white', backgroundColor: '#276e42', padding: '20px' }} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF"><path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm154-396Z" /></svg>
                    Charging</strong></div>


                <div className='r-stack'>
                    <div className='fragment fade-out' data-fragment-index="0" style={{ textAlign: 'left', margin: '50px', width: '50%' }}>
                        <p>There are several notable up-and-coming advancements in EV charging technology, such as ultra-fast charging stations and wireless charging.</p>
                    </div>

                    <div className='fragment fade-in' data-fragment-index="0" style={{ textAlign: 'left', margin: '50px', width: '55%' }}>
                        <p>Ultra-fast charging technology (i.e.,
                            <OverlayTrigger placement="bottom" overlay={popover}>
                                <strong style={{ color: '#276e42' }}>level 4 charging</strong>
                            </OverlayTrigger>
                            ) is capable of delivering more power than current DC fast chargers.</p>
                    </div>
                </div>

            </section>

            <section className='h-100 m-auto' data-auto-animate data-background-image={pic4} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1}>
                <div className='r-stack '>
                    <div >
                        <div style={{ textAlign: 'left', width: '95%' }}>
                            <p style={{ backgroundColor: '#276e42', color: 'white', padding: '25px', fontSize: '30px' }} >Which of the following statements accurately describes a Level 3 EV charging station?</p>
                        </div>

                        <div className='r-stack '>
                            {
                                value === "" ? <div style={{ margin: 'auto', textAlign: 'left' }}>
                                    <Form className="m-2 p-2" onChange={(e) => handleClick(e.target.id)} >
                                   
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="1" label="They are the most basic type and have the slowest charging speeds compared to Level 1 and Level 2 chargers." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="2" label="They use a standard 120V outlet and are typically found in residential settings." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="3" label="They are designed for slow, overnight charging and are primarily used in home garages." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px', }} type='radio' id="4" label="They are also known as DC fast chargers and can charge an electric vehicle to 80% in about 30 minutes." name="group1" />
                                    </Form>


                                </div> : null
                            }

                            {
                                value === "1" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                   <h2>Incorrect</h2>
                                   <p>They are also known as DC fast chargers and can charge an electric vehicle to 80% in about 30 minutes.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "2" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>They are also known as DC fast chargers and can charge an electric vehicle to 80% in about 30 minutes.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "3" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>They are also known as DC fast chargers and can charge an electric vehicle to 80% in about 30 minutes.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "4" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Correct!</h2>
                                    <p>They are also known as DC fast chargers and can charge an electric vehicle to 80% in about 30 minutes.</p>
                                    
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                        </div>
                    </div>
                </div>
            </section>

            <section data-auto-animate data-background-image={pic5} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1} >
                <div className='jusity-content-space-between'  >
                    <div className='d-flex justify-cotent-center align-items-center' style={{ textAlign: 'left', margin: '50px', position: 'top', backgroundColor: '#276e42', width: 'fit-content' }}>
                        <svg style={{ margin: '20px' }} xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF"><path d="m625-674-44-44 60-60H312v-60h329l-60-60 44-44 134 134-134 134ZM180-190h600v-214H180v214Zm105-52q23 0 39-16t16-39q0-23-16-39t-39-16q-23 0-39 16t-16 39q0 23 16 39t39 16Zm390 0q23 0 39-16t16-39q0-23-16-39t-39-16q-23 0-39 16t-16 39q0 23 16 39t39 16ZM120-464h634l-54-163H237l60 60-44 44-134-134 134-134 44 44-60 60h475q14 0 27 11t16 24l85 248v334q0 12-9 21t-21 9h-20q-12 0-21-9t-9-21v-60H200v60q0 12-9 21t-21 9h-20q-12 0-21-9t-9-21v-394Zm60 60v214-214Z" /></svg>
                        <strong style={{ fontSize: '60px', color: 'white', padding: '20px' }} >Autonomous Driving</strong>
                    </div>
                    <div style={{ height: '200px' }} />
                    <div style={{ backgroundColor: '#e6e6e6', width: '90%', fontSize: '40px', textAlign: 'left', margin: 'auto', padding: '30px' }}>
                        EVs are at the forefront of the evolution in autonomous driving technology, as the simpler electric drivetrains allow for better integration with digital systems.
                    </div>
                </div>


            </section>

            <section data-auto-animate data-background-image={pic6} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1} data-background-color="black" >
                <div className='jusity-content-space-between'  >
                    <div className='d-flex justify-cotent-center align-items-center' style={{ textAlign: 'left', margin: '50px', position: 'top', backgroundColor: '#276e42', width: 'fit-content' }}>
                        <svg style={{ translate: '10px' }} xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF"><path d="M234-40q-47.5 0-80.75-33.25T120-154q0-47.5 33.25-80.75T234-268q14 0 24.5 2.5T280-258l85-106q-19-23-29-52.5t-5-61.5l-121-41q-15 25-39.5 39T114-466q-47.5 0-80.75-33.25T0-580q0-47.5 33.25-80.75T114-694q47.5 0 80.75 33.25T228-580v4l122 42q18-32 43.5-49t56.5-24v-129q-39-11-61.5-43T366-846q0-47.5 33.25-80.75T480-960q47.5 0 80.75 33.25T594-846q0 35-23 67t-61 43v129q31 7 57 24t44 49l121-42v-4q0-47.5 33.25-80.75T846-694q47.5 0 80.75 33.25T960-580q0 47.5-33.25 80.75T846-466q-32 0-57-14t-39-39l-121 41q5 32-4.5 61.5T595-364l85 106q11-5 21.5-7.5t24.06-2.5Q774-268 807-234.75T840-154q0 47.5-33.25 80.75T726-40q-47.5 0-80.75-33.25T612-154q0-20 5.5-36t15.5-31l-85-106q-32.13 17-68.56 17Q443-310 411-327l-84 107q10 15 15.5 30.5T348-154q0 47.5-33.25 80.75T234-40ZM114.04-526q22.96 0 38.46-15.54 15.5-15.53 15.5-38.5 0-22.96-15.54-38.46-15.53-15.5-38.5-15.5Q91-634 75.5-618.46 60-602.93 60-579.96 60-557 75.54-541.5q15.53 15.5 38.5 15.5Zm120 426q22.96 0 38.46-15.54 15.5-15.53 15.5-38.5 0-22.96-15.54-38.46-15.53-15.5-38.5-15.5-22.96 0-38.46 15.54-15.5 15.53-15.5 38.5 0 22.96 15.54 38.46 15.53 15.5 38.5 15.5Zm246-692q22.96 0 38.46-15.54 15.5-15.53 15.5-38.5 0-22.96-15.54-38.46-15.53-15.5-38.5-15.5-22.96 0-38.46 15.54-15.5 15.53-15.5 38.5 0 22.96 15.54 38.46 15.53 15.5 38.5 15.5Zm.46 422q37.5 0 63.5-26.5t26-64q0-37.5-26.1-63.5T480-550q-37 0-63.5 26.1T390-460q0 37 26.5 63.5t64 26.5Zm245.54 270q22.96 0 38.46-15.54 15.5-15.53 15.5-38.5 0-22.96-15.54-38.46-15.53-15.5-38.5-15.5-22.96 0-38.46 15.54-15.5 15.53-15.5 38.5 0 22.96 15.54 38.46 15.53 15.5 38.5 15.5Zm120-426q22.96 0 38.46-15.54 15.5-15.53 15.5-38.5 0-22.96-15.54-38.46-15.53-15.5-38.5-15.5-22.96 0-38.46 15.54-15.5 15.53-15.5 38.5 0 22.96 15.54 38.46 15.53 15.5 38.5 15.5ZM480-846ZM114-580Zm366 120Zm366-120ZM234-154Zm492 0Z" /></svg>
                        <strong style={{ fontSize: '60px', color: 'white', padding: '20px' }} >Vehicle 2 Grid</strong>
                    </div>
                    <div style={{ backgroundColor: 'rgba(53, 53, 53, 0.7)' }}>
                        <div style={{ width: '90%', fontSize: '35px', textAlign: 'left', margin: 'auto', padding: '30px', color: 'white' }}>
                            Vehicle 2 Grid (V2G) technology leverages the battery storage capacity of EVs to enhance grid stability, store excess renewable energy, and provide backup power during peak demand periods when the vehicle is connected to a charger.
                        </div>

                        <div style={{ width: '90%', fontSize: '35px', textAlign: 'left', margin: 'auto', padding: '30px', color: 'white' }}>
                            There are hurdles to this technology around regulation, compatibility, grid communication, and cybersecurity, but V2G pilots are occuring in Denmark, the UK, and the Netherlands.
                        </div>
                    </div>

                </div>


            </section>
        </Slide>
    );
}

export default Slide2;
