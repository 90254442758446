import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Slide from '../../Components/RevealComponents/Slide';

import ligths from '../../images/econimpactslides/environmental-11-lightmap.png';

import "./graph.scss";

const popover = (
    <Popover >
        <Popover.Body>
        Vehicle 2 Grid (V2G) technology leverages the battery storage capacity of EVs to enhance grid stability, store excess renewable energy, and provide backup power during peak demand periods when the vehicle is connected to a charger.
        </Popover.Body>
    </Popover>
);

const Slide3 = () => {
    const [value, setValue] = useState("");

    // Similar to componentDidMount and componentDidUpdate:
    function handleClick(v) {
        setValue(v);
    }

    return (
        <Slide>
            <section className='h-100' data-auto-animate data-background-image={ligths} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1} data-background-color="black">
                <div className='h-100 d-flex justify-content-center align-items-center'>
                    <div style={{ width: '50%', padding: '20px', color: 'white', textAlign: 'left' }}>
                        {/* Back pane */}
                        <div style={{ height: '1000%', width: '100%', backgroundColor: 'rgba(53, 53, 53, 0.5)', position: 'absolute', top: '-100%', left: '-50%', zIndex: '-999' }} />
                        <p style={{ margin: 'auto' }}>Using <OverlayTrigger  placement="bottom" overlay={popover}>
                            <strong style={{ textDecoration: 'underline' }}> V2G Technology</strong>
                        </OverlayTrigger>, EVs can contribute to reducing peak grid demand and providing support to the grid, improving the environmental impact of renewable energy.</p>
                    </div>
                    <div className='align-content-center' style={{ width: '50%', padding: '20px', color: 'white', textAlign: 'left' }} >
                        {/* Back pane */}
                        <div style={{ height: '1000%', width: '100%', backgroundColor: 'rgba(53, 53, 53, 0.5)', position: 'absolute', top: '-100%', left: '50%', zIndex: '-999' }} />
                        <table id="q-graph" >
                            <tbody>
                                <tr className="qtr" id="q1">
                                    <th scope="row">Power Generation</th>
                                    <td className="pg bar" style={{ height: '150px', color: 'rgb(201, 181, 67)' }} label="Power Generation" />
                                </tr>
                                <tr className="qtr" id="q2">
                                    <th scope="row">Power Load</th>
                                    <td className="pl bar" style={{ height: '150px', color: 'rgb(46, 168, 184)' }} label="Power Load" />
                                </tr>
                                <tr className="qtr" id="q3">
                                    <th scope="row">EV Batteries</th>
                                    <td className="ev bar" style={{ height: '0px', color: 'rgb(43, 100, 10)' }} label="EV Batteries" />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </section>

            <section className='h-100' data-auto-animate data-background-image={ligths} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1} data-background-color="black">
                <div className='h-100 d-flex justify-content-center align-items-center ' style={{ height: '100%' }}>
                    <div style={{ width: '50%', padding: '20px', color: 'white', textAlign: 'left' }}>
                        {/* Back pane */}
                        <div style={{ height: '1000%', width: '100%', backgroundColor: 'rgba(53, 53, 53, 0.5)', position: 'absolute', top: '-100%', left: '-50%', zIndex: '-999' }} />

                        <p >As the generation of renewable energy varies or load increases, connected EVs can act as a buffer and contribute to the grid.</p>
                        <p >This will support the adoption of renewable energy sources and improve environmental outcomes.</p>

                    </div>
                    <div className='align-content-center' style={{ width: '50%', padding: '20px', color: 'white', textAlign: 'left' }} >
                        {/* Back pane */}
                        <div style={{ height: '1000%', width: '100%', backgroundColor: 'rgba(53, 53, 53, 0.5)', position: 'absolute', top: '-100%', left: '50%', zIndex: '-999' }} />


                        <table id="q-graph" style={{ position: 'bottom' }}>
                            <tbody>
                                <tr className="qtr" id="q1">
                                    <th scope="row">Power Generation</th>
                                    <td className="pg bar" style={{ height: '100px', color: 'rgb(201, 181, 67)' }} label="Power Generation" />
                                </tr>
                                <tr className="qtr" id="q2">
                                    <th scope="row">Power Load</th>
                                    <td className="pl bar" style={{ height: '200px', color: 'rgb(46, 168, 184)' }} label="Power Load" />
                                </tr>
                                <tr className="qtr" id="q3">
                                    <th scope="row">V2G</th>
                                    <td className="ev bar" style={{ height: '85px', color: 'rgb(43, 100, 10)' }} label="EV Batteries" />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </section>

            <section className='h-100' data-auto-animate data-background-image={ligths} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1} data-background-color="black">
                <div className='r-stack '>
                    <div >
                        <div style={{ textAlign: 'left', width: '95%' }}>
                            <p style={{ backgroundColor: '#276e42', color: 'white', padding: '25px', fontSize: '25px' }} >
                            Which of the following statements best describes the impact of Canada's renewable energy resources on the environmental benefits of MHDZEVs? 
                            </p>
                        </div>

                        <div className='r-stack' style={{ backgroundColor: 'rgba(53, 53, 53, 0.6)' }}>
                            {
                                value === "" ? <div style={{ margin: 'auto', textAlign: 'left', color: 'white' }}>
                                    <Form className="m-2 p-2" onChange={(e) => handleClick(e.target.id)} >

                                        <Form.Check style={{ fontSize: '25px' }} type='radio' id="1" label="The environmental benefits of MHDZEVs in Canada are limited to regions with hydroelectric power and do not significantly improve in areas with wind or solar energy." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '25px' }} type='radio' id="2" label="MHDZEVs in Canada achieve the greatest environmental benefits when charged with electricity from the country's diverse renewable energy sources, including hydroelectric, wind, and solar power, considering regional variations in energy mix." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '25px' }} type='radio' id="3" label="The environmental impact of MHDZEVs in Canada is not influenced by the type of renewable energy used, as all renewable sources have the same effect on reducing emissions." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '25px' }} type='radio' id="4" label="Only urban areas in Canada with access to renewable energy infrastructure benefit from the environmental advantages of MHDZEVs, while rural areas see minimal impact." name="group1" />
                                    </Form>


                                </div> : null
                            }

                            {
                                value === "1" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%', color: 'white' }}>
                                    <h2>Incorrect</h2>
                                    <p>MHDZEVs in Canada achieve the greatest environmental benefits when charged with electricity from the country's diverse renewable energy sources, including hydroelectric, wind, and solar power, considering regional variations in energy mix.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px', color: 'white' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "2" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%', color: 'white' }}>
                                    <h2>Correct!</h2>
                                    <p>MHDZEVs in Canada achieve the greatest environmental benefits when charged with electricity from the country's diverse renewable energy sources, including hydroelectric, wind, and solar power, considering regional variations in energy mix.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px', color: 'white' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "3" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%', color: 'white' }}>
                                    <h2>Incorrect</h2>
                                    <p>MHDZEVs in Canada achieve the greatest environmental benefits when charged with electricity from the country's diverse renewable energy sources, including hydroelectric, wind, and solar power, considering regional variations in energy mix.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px', color: 'white' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "4" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%', color: 'white' }}>
                                    <h2>Incorrect</h2>
                                    <p>MHDZEVs in Canada achieve the greatest environmental benefits when charged with electricity from the country's diverse renewable energy sources, including hydroelectric, wind, and solar power, considering regional variations in energy mix.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px', color: 'white' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                        </div>
                    </div>
                </div>
            </section>

        </Slide>
    )
};

export default Slide3;
