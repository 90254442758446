import React, {useState} from 'react';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Slide from '../../Components/RevealComponents/Slide';

import goat from '../../images/econfactorslides/economic-11-goat.png'
import farm from '../../images/econfactorslides/economic-12-farm.png'

const Slide6 = () => {
    const [value, setValue] = useState("");

    // Similar to componentDidMount and componentDidUpdate:
    function handleClick(v) {
        setValue(v);
    }

    return (
        <Slide >
            {/* style={{ position: 'absolute', bottom: '-2%', right: '-15%', height: '80%', width: '80%', zIndex: '-999' }} */}
            <Image src={goat} alt='Graph' style={{ position: 'absolute', bottom: '-2%', right: '-15%', height: '65%', width: '65%', zIndex: '-999' }} />

            <section data-auto-animate data-background-color="#276e42" style={{ margin: 'auto' }}>
                <div style={{ color: 'white', fontSize: '60px', fontWeight: 'bold', textAlign: 'left', translate: '3% 20%' }}>
                    The economic benefit doesn't have to be limited to vehicle owners.
                </div>
                <div style={{ width: '50%', color: 'white', fontSize: '55px', textAlign: 'left', translate: '3% 30%' }}>
                    Businesses that adapt to the changing transportation landscape can also benefit.
                </div>
            </section>
            <section data-auto-animate data-background-image={farm} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1} >
                <p style={{ textAlign: 'left', fontSize: '45px', fontWeight: 'bold', padding: '20px' }}>For example:</p>
                <p style={{ textAlign: 'left', fontSize: '45px', fontWeight: 'bold', padding: '20px' }}>A goat farm in Ontario used a federal tourism grant to install two EV charging stations for visitors.</p>
            </section>
            <section data-auto-animate data-background-image={farm} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1} >
                <p style={{ textAlign: 'left', fontSize: '45px', fontWeight: 'bold', padding: '20px', width: '92%', zIndex: '999', lineHeight: '60px' }}>
                    Although it may not be a significant source of income, it improves rural charging infrastructure,
                    allows for more people to visit the farm and cafe, and is now a more environmentally friendly business.
                </p>
            </section>
            <section className='h-100 m-auto' data-auto-animate data-background-image={farm} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1}>
                <div className='r-stack '>
                    <div >
                        <div style={{ textAlign: 'left', width: '95%' }}>
                            <p style={{ backgroundColor: '#276e42', color: 'white', padding: '25px', fontSize: '30px' }} >Which of the following is a potential economic benefit of adopting medium- and heavy-duty zero-emission vehicles (MHDZEVs) in the transportation sector?</p>
                        </div>

                        <div className='r-stack' style={{ backgroundColor: 'rgba(226, 226, 226, 0.7)' }}>
                            {
                                value === "" ? <div style={{ margin: 'auto', textAlign: 'left' }}>
                                    <Form className="m-2 p-2" onChange={(e) => handleClick(e.target.id)} >

                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="1" label="Increased fuel costs due to higher prices for hydrogen compared to diesel." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="2" label="Reduction in operational costs over time due to lower maintenance and fuel expenses." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="3" label="Higher upfront costs with no long-term savings due to the complexity of zero-emission technologies." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="4" label="No significant economic impact, as MHDZEVs do not affect job creation or industry growth." name="group1" />
                                    </Form>


                                </div> : null
                            }

                            {
                                value === "1" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                     <h2>Incorrect</h2>
                                    <p>Reduction in operational costs over time due to lower maintenance and fuel expenses.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "2" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Correct!</h2>
                                    <p>Reduction in operational costs over time due to lower maintenance and fuel expenses.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "3" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Reduction in operational costs over time due to lower maintenance and fuel expenses.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "4" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Reduction in operational costs over time due to lower maintenance and fuel expenses.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                        </div>
                    </div>
                </div>
            </section>

        </Slide>
    );
}

export default Slide6;
