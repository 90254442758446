import React, { useEffect } from 'react';
import Reveal from 'reveal.js';
import RevealNotes from 'reveal.js/plugin/notes/notes';
import RevealMarkdown from 'reveal.js/plugin/markdown/markdown';
import PropTypes from 'prop-types';
import revealOptions from './revealOptions';

import 'reveal.js/dist/reveal.css';

const Deck = ({ children }) => {
  useEffect(() => {

    Reveal.initialize({
      // view: 'scroll',
      scrollActivationWidth: null,
      // scrollLayout: 'full',
      // scrollSnap: 'mandatory',
      ...revealOptions,
      plugins: [RevealNotes, RevealMarkdown],
    });


  });

  return (
    <div className="reveal" style={{ height: '100%' }}>
      <div className="slides">{children}</div>
    </div>
  );
};

Deck.propTypes = {
  children: PropTypes.node,
};

export default Deck;
