import React from 'react';
import TitleSlide from './TitleSlide';
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';

export default [
  <TitleSlide key="1"/>,
  <Slide1 key="2"/>,
  <Slide2 key="3"/>,
  <Slide3 key="4"/>,
  <Slide4 key="5"/>
];
