import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import Slide from '../../Components/RevealComponents/Slide';



const CarbonMonoxide = () => {
    const [showHealthCarbon, setShowHealth] = useState(false);

    return (
        <Slide >
            <section className='h-100'  >
                <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Toggle to see health benefits</div>
                <div className='d-flex justify-content-between align-items-center'>
                <Form className='d-flex justify-content-between align-items-center' style={{  fontSize: '30px' }}>
                        <Form.Label htmlFor="custom-switch" className='m-1'>Internal Combustion Engine</Form.Label>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"

                            onChange={() => setShowHealth(!showHealthCarbon)}
                            checked={showHealthCarbon || false}
                        />
                        <Form.Label htmlFor="custom-switch" className='m-1'>Zero-Emission Vehicle</Form.Label>
                    </Form>


                    <a className='d-flex justify-content-center align-items-center' href='mhdzevhealthbenefits#/1' style={{ textDecorationLine: 'none' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" /></svg>
                        <div style={{ fontStyle: 'normal', fontSize: '30px', paddingRight: '20px', textAlign: 'left' }}>Back</div>
                    </a>
                </div>
                {!showHealthCarbon ?
                    <div style={{ backgroundColor: '#262626', padding: '30px' }}>

                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <h2>Carbon Monoxide</h2>
                            </div>

                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                Traditional medium and heavy-duty vehicles, such as diesel trucks and buses, emit carbon monoxide (CO), a colourless, odourless, and highly toxic gas.
                            </div>
                        </div>
                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>
                            <h3>Health Risks:</h3>
                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                <div>

                                    <strong>Premature Death
                                    </strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        Severe CO poisoning can be fatal. It binds with hemoglobin in the blood, reducing the oxygen-carrying capacity and leading to tissue hypoxia. Carbon monoxide poisoning is responsible for 300 deaths and 200 hospitalizations per year in Canada. (Health Infobase Canada, 2023)
                                    </p>

                                </div>

                            </div>


                        </div>
                    </div>
                    :
                    <div style={{ backgroundColor: '#276e42', padding: '30px' }}>

                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>

                            <h2>Say NO to CO</h2>

                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                MHDZEVs don’t emit carbon dioxide. While there are other sources of carbon monoxide, such as clothes dryers, and open fires burning gas, oil or charcoal, we often have carbon monoxide detectors to warn us. Enclosed spaces like garages often lack carbon monoxide detection.
                            </div>
                        </div>

                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>
                            <h3>Health Benefits:</h3>
                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                <div>

                                    <strong>Elimination of Acute Poisoning Risks</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        With no CO emissions, the risks of acute CO poisoning from vehicles are eliminated, ensuring safer air for drivers, passengers, and the public. (Chen et al., 2007)
                                    </p>

                                </div>

                            </div>

                        </div>
                    </div>
                }
            </section>

        </Slide>

    )
};

export default CarbonMonoxide;
