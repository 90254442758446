import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import car1 from '../../images/comparisonslides/zevarbocequess.png'
import car2 from '../../images/comparisonslides/gilliglowfloor.png'

const Class7 = () => (
    <Slide >
        <section>

            <div className='d-flex justify-content-between align-items-center' style={{ fontSize: '30px', color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '100%', textAlign: 'center' }}>
            <p className='p-2'><strong>Class 7: Arboc Equess Charge</strong></p>
            <a className='d-flex justify-content-center align-items-center' href='mhdzevcomparison#/1' style={{ textDecorationLine: 'none' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" /></svg>
                    <div style={{ color: 'white', fontStyle: 'normal', paddingRight: '20px', textAlign: 'left' }}>Back</div>
                </a>
            </div>

            <div style={{ padding: '1px' }} >
                <div className='d-flex justify-content-center align-items-center'>
                    <div style={{ textAlign: 'center', margin: '20px', width: '50%' }}>
                        <Image src={car1} style={{ height: '85%', width: '85%' }} alt='Arboc Equess Charge' />

                    </div>

                    <div style={{ textAlign: 'center', margin: '20px', width: '50%' }}>
                        <Image src={car2} alt='Gillig Low Floor' />

                    </div>

                </div>
                <div className='d-flex justify-content-between align-items-start'>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                    <div style={{ fontSize: '25px' }}>Arboc Equess Charge</div>
                        <ul >
                            <li style={{ fontSize: '15px' }}><strong>Range: </strong>Up to 370 km (230 miles)</li>
                            <li style={{ fontSize: '15px' }}><strong>Passenger Capacity: </strong>Up to 33 People</li>
                            <li style={{ fontSize: '15px' }}><strong>Peak Power: </strong>586 Horsepower (437 KW/H)</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>Up to $100,000</li>
                            {/* <li style={{ fontSize: '15px' }}><strong>Total Cost: </strong>Not publicly available, but likely a higher cost upfront when compared to the Gillig Low Floor. However, 
                            the Arboc Equess Charge will have significantly lower running costs, along with the $100,000 rebate for its purchase.
                            </li> */}
                        </ul>
                    </div>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                    <div style={{ fontSize: '25px' }}>Gillig Low Floor</div>
                        <ul>
                            <li style={{ fontSize: '15px' }}><strong>Passenger Capacity: </strong>Up to 40 people, depending on the length of the floor</li>
                            <li style={{ fontSize: '15px' }}><strong>Peak Power: </strong>Up to 380 Horsepower when powered by L9 Cummins Engine</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>N/A</li>
                            {/* <li style={{ fontSize: '15px' }}><strong>Total Cost: </strong>Not publicly available, likely a lower upfront cost compared to the Arboc Equess Charge but with higher running costs</li> */}
                        </ul>
                    </div>
                </div>

            </div>
            
            </section>
    </Slide>
);

export default Class7;
