import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import Slide from '../../Components/RevealComponents/Slide';



const FireHazards = () => {
    const [showHealthFire, setShowHealth] = useState(false);

    return (
        <Slide >
            <section className='h-100'  >
                <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Toggle to see health benefits</div>
                <div className='d-flex justify-content-between align-items-center'>
                <Form className='d-flex justify-content-between align-items-center' style={{  fontSize: '30px' }}>
                        <Form.Label htmlFor="custom-switch" className='m-1'>Internal Combustion Engine</Form.Label>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            onChange={() => setShowHealth(!showHealthFire)}
                            checked={showHealthFire || false}
                        />
                        <Form.Label htmlFor="custom-switch" className='m-1'>Zero-Emission Vehicle</Form.Label>
                    </Form>


                    <a className='d-flex justify-content-center align-items-center' href='mhdzevhealthbenefits#/1' style={{ textDecorationLine: 'none' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" /></svg>
                        <div style={{ fontStyle: 'normal', fontSize: '30px', paddingRight: '20px', textAlign: 'left' }}>Back</div>
                    </a>
                </div>
                {!showHealthFire ?
                    <div style={{ backgroundColor: '#262626', padding: '30px' }}>

                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <h2>Fire Hazard</h2>
                            </div>

                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                While we may be familiar with the risks, a fuel tank containing flammable liquid represents a significant risk. Diesel and gasoline leaks can easily ignite, leading to vehicle fires that pose risks to drivers, passengers, and nearby individuals. Combustion engines can also overheat, increasing the risk of engine fires.

                            </div>
                        </div>
                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>
                            <h3>Health Risks:</h3>
                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                <div>

                                    <strong>Respiratory and Cardiovascular Issues</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        Exposure to smoke and toxic fumes from vehicle fires can lead to respiratory problems, cardiovascular issues, and other severe health complications. (Miri et al., 2018)
                                    </p>

                                </div>

                            </div>


                        </div>
                    </div>
                    :
                    <div style={{ backgroundColor: '#276e42', padding: '30px' }}>

                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>

                            <h2>No Combustion Needed</h2>

                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                MHDZEVs, such as electric trucks and buses, do not rely on flammable fuels like diesel or gasoline, eliminating the risk of fuel leak fires. Electric motors also generate less heat than combustion engines, reducing the risk of engine fires. In addition, modern electric vehicles are equipped with advanced battery management systems designed to prevent overheating and electrical fires.
                            </div>
                        </div>

                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>
                            <h3>Health Benefits:</h3>
                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                <div>

                                    <strong>Increased Drive and Passenger Safety</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        Reduced fire risks contribute to safer transportation for drivers and passengers. (Malmgren, 2016)
                                    </p>

                                </div>
                                <div>

                                    <strong>Enhanced Public Safety</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        A lower incidence of vehicle fires decreases the risks for pedestrians, other drivers, and first responders. (Abrams, 2023)
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                }
            </section>

        </Slide>

    )
};

export default FireHazards;
