import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import Slide from '../../Components/RevealComponents/Slide';

const Slide4 = () => {

    const [value, setValue] = useState("");

    // Similar to componentDidMount and componentDidUpdate:
    function handleClick(v) {
        setValue(v);
    }

    return (
        <Slide>
            <section data-prevent-swipe>
                <div style={{ backgroundColor: '#e6e6e6', textAlign: 'left', fontSize: '50px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px', marginBottom: '10px' }}>
                    <p style={{ translate: '-25px', fontSize: '40px', }}><strong style={{ color: 'white', backgroundColor: 'black', fontSize: '50px', paddingTop: '5px', paddingLeft: '30px', paddingRight: '10px', marginRight: '10px' }}>Canada</strong>is also investing in frameworks</p>
                    <p style={{ translate: '-25px', fontSize: '40px', paddingLeft: '30px', }}>and incentives for MHDZEVs</p>
                </div>

                <div className='r-stack '>
                    <div className='fragment fade-out' data-fragment-index="0">
                        <div style={{ textAlign: 'left', width: '95%' }}>
                            <p style={{ backgroundColor: '#276e42', color: 'white', padding: '25px', fontSize: '30px' }} >Do you know how much money the iMHZEV Program provides for Medium and Heavy Duty Zero Emission Vehicles?</p>
                        </div>

                        <div className='r-stack '>
                            {
                                value === "" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '55%' }}>
                                    <Form className="m-2 p-2" onChange={(e) => handleClick(e.target.id)} >

                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="1" label="$42,000" name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="2" label="Up to $80,000" name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="3" label="Up to $200,000" name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px', }} type='radio' id="4" label="Up to 50% of the vehicle's value" name="group1" />
                                    </Form>


                                </div> : null
                            }

                            {
                                value === "1" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '70%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Starting in July of 2022, Canada began providing up to <strong>$200,000</strong> in incentives towards the purchase of MHDVs.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px'}} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "2" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '70%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Starting in July of 2022, Canada began providing up to <strong>$200,000</strong> in incentives towards the purchase of MHDVs.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px'}} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "3" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '70%' }}>
                                    <h2>Correct!</h2>
                                    <p>Starting in July of 2022, Canada began providing up to <strong>$200,000</strong> in incentives towards the purchase of MHDVs.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px'}} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "4" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '70%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Starting in July of 2022, Canada began providing up to <strong>$200,000</strong> in incentives towards the purchase of MHDVs.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px'}} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                        </div>
                    </div>
                    <div className='fragment fade-in' data-fragment-index="0">

                        <div style={{ margin: 'auto', fontSize: '35px', width: '85%', textAlign: 'left', paddingBottom: '10px', fontWeight: '500px' }}><strong>The incentives for Medium- and Heavy-Duty Zero-Emission Vehicles (iMHZEV) program</strong></div>

                        <ul>
                            <li style={{ textAlign: 'left', fontSize: '30px', padding: '5px' }}>Four year program providing approximately $550 million in purchasing incentives</li>
                            <li style={{ textAlign: 'left', fontSize: '30px', padding: '5px' }}>Covers up to 50% of the difference between a ZEV and its traditional counterpart, up to $200k per vehicle</li>
                            <li style={{ textAlign: 'left', fontSize: '30px', padding: '5px' }}>Applies to Class 2B to Class 8, with 499 models eligible</li>
                        </ul>
                    </div>
                </div>
            </section>
        </Slide>
    );
};

export default Slide4;
