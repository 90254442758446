import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Slide from '../../Components/RevealComponents/Slide';

import pic from '../../images/techinnoslides/hydrogen.png'


const Slide3 = () => {

    const [showHFC, setHFC] = useState(false);
    const [showRA, setRA] = useState(false);
    const [showIL, setIL] = useState(false);
    const [showCC, setCC] = useState(false);
    const [value, setValue] = useState("");

    // Similar to componentDidMount and componentDidUpdate:
    function clickShowHFC(v) {
        setHFC(v);
    }

    function clickShowRA(v) {
        setRA(v);
    }

    function clickShowIL(v) {
        setIL(v);
    }

    function clickShowCC(v) {
        setCC(v);
    }



    // Similar to componentDidMount and componentDidUpdate:
    function handleClick(v) {
        setValue(v);
    }


    return (
        <Slide >
            <section className='h-100' data-background-image={pic} data-background-size="fit" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={0.3} >

                <div className='jusity-content-space-between'>
                    <div style={{ textAlign: 'right', }}>
                        <div style={{ padding: '10px', translate: '-20px -20px', width: '70%' }} >
                            <p style={{ fontSize: '35px', color: 'white', backgroundColor: '#276e42', padding: '20px' }} ><strong >Hydrogen Fuel Cell Technology</strong></p>
                            <p style={{ fontSize: '30px' }} ><strong >is also a crucial part of the conversation</strong></p>
                        </div>


                    </div>
                    <div style={{ height: '200px' }} />
                    <div style={{ width: '90%', backgroundColor: '#e2e2e2', padding: '20px' }}>
                        <div style={{ width: '90%', fontSize: '30px', textAlign: 'left', padding: '10px', translate: '20px' }}>
                            Hydrogen Fuel Cell Technology represents a transformative advancement in the pursuit of zero-emissions vehicles.
                        </div>
                        <br />
                        <div style={{ width: '90%', fontSize: '30px', textAlign: 'left', padding: '10px', translate: '20px' }}>
                            By converting hydrogen into electricity, these cells power vehicles with no harmful emissions, offering a clean, efficient alternative to traditional fossil fuels
                        </div>
                    </div>

                </div>



            </section>

            <section data-background-image={pic} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={0.3} >
                <div>
                    <div style={{ textAlign: 'left', fontSize: '40px', width: '70%', margin: '30px', translate: '60px' }}>
                        There are 4 additional challenges specific to adopting Hydrogen ZEVs in <strong style={{ color: '#276e42' }}>Rural and Remote Areas</strong>
                    </div>
                    <div style={{ fontSize: '25px', margin: '30px' }}>Click on each box to see how innovation can address the challenges</div>
                </div>

                <div style={{ height: '2px' }} />

                {!showHFC && !showRA && !showIL && !showCC ?
                    <Container fluid>

                        <Row>
                            <Button variant='light'
                                onClick={() => { clickShowHFC(true) }}
                                style={{ border: '1px solid #e6e6e6', backgroundColor: '#e6e6e6', color: 'black', fontSize: '40px', fontWeight: 'bold', height: '150px', width: '325px', margin: 'auto', boxShadow: '-5px -5px 8px #888888' }}
                            >
                                Infrastructure Development
                            </Button>
                            <Button variant='light'
                                onClick={() => { clickShowRA(true) }}
                                style={{ border: '1px solid #e6e6e6', backgroundColor: '#e6e6e6', color: 'black', fontSize: '40px', fontWeight: 'bold', height: '150px', width: '325px', margin: 'auto', boxShadow: '5px -5px 8px #888888' }}
                            >
                                Production Costs
                            </Button>
                        </Row>
                        <Row >
                            <div style={{ height: '50px' }} />
                        </Row>
                        <Row>
                            <Button variant='light'
                                onClick={() => { clickShowIL(true) }}
                                style={{ border: '1px solid #e6e6e6', backgroundColor: '#e6e6e6', color: 'black', fontSize: '40px', fontWeight: 'bold', height: '150px', width: '325px', margin: 'auto', boxShadow: '-5px 5px 8px #888888' }}
                            >
                                Energy Efficiency
                            </Button>
                            <Button variant='light'
                                onClick={() => { clickShowCC(true) }}
                                style={{ border: '1px solid #e6e6e6', backgroundColor: '#e6e6e6', color: 'black', fontSize: '40px', fontWeight: 'bold', height: '150px', width: '325px', margin: 'auto', boxShadow: '5px 5px 8px #888888' }}
                            >
                                Storage and Transportation
                            </Button>
                        </Row>
                    </Container> : null
                }
                {showHFC ?
                    <Container fluid>
                        <div style={{ backgroundColor: '#e6e6e6', boxShadow: '-5px -5px 8px #888888' }}>
                            <p><strong>Infrastructure Development</strong></p>
                            <p style={{ fontSize: '30px', width: '60%', margin: 'auto' }}>Expansion and innovation in refuelling stations are improving accessibility.</p>
                            <Button variant='light' className='m-3' onClick={() => { clickShowHFC(false) }} style={{ backgroundColor: '#276e42', color: 'white' }}>Close</Button>
                        </div>
                    </Container> : null

                }

                {showRA ?
                    <Container fluid>
                        <div style={{ backgroundColor: '#e6e6e6', boxShadow: '5px -5px 8px #888888' }}>
                            <p><strong>Production Costs</strong></p>
                            <p style={{ fontSize: '30px', width: '70%', margin: 'auto' }}>
                                Research is reducing the cost of production, storage, and fuel cells.
                            </p>
                            <Button variant='light' className='m-3' onClick={() => { clickShowRA(false) }} style={{ backgroundColor: '#276e42', color: 'white' }}>Close</Button>
                        </div>
                    </Container> : null

                }

                {showIL ?
                    <Container fluid>
                        <div style={{ backgroundColor: '#e6e6e6', boxShadow: '-5px 5px 8px #888888' }}>
                            <p><strong>Energy Efficiency</strong></p>
                            <p style={{ fontSize: '30px', width: '65%', margin: 'auto' }}>
                                Advances in green hydrogen production are improving the total lifecycle and lowering environmental impact and cost.
                            </p>
                            <Button variant='light' className='m-3' onClick={() => { clickShowIL(false) }} style={{ backgroundColor: '#276e42', color: 'white' }}>Close</Button>
                        </div>
                    </Container> : null

                }

                {showCC ?
                    <Container fluid>
                        <div style={{ backgroundColor: '#e6e6e6', boxShadow: '5px 5px 8px #888888' }}>
                            <p><strong>Storage and Transportation</strong></p>
                            <p style={{ fontSize: '30px', width: '75%', margin: 'auto' }}>
                                New materials and designs are enhancing lifespan and reliability.
                            </p>
                            <Button variant='light' className='m-3' onClick={() => { clickShowCC(false) }} style={{ backgroundColor: '#276e42', color: 'white' }}>Close</Button>
                        </div>
                    </Container> : null

                }

            </section>


            <section data-background-image={pic} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={0.3} >
                <div className='r-stack '>
                    <div >
                        <div style={{ textAlign: 'left', width: '95%' }}>
                            <p style={{ backgroundColor: '#276e42', color: 'white', padding: '25px', fontSize: '30px' }} >
                                <strong>True or False: </strong>Hydrogen fuel cells not only provide power for propulsion but also require a high-pressure storage system for the hydrogen gas, which typically operates at pressures of 350 to 700 bar.
                            </p>
                        </div>

                        <div className='r-stack m-auto' style={{ backgroundColor: 'rgba(226, 226, 226, 0.5)', textAlign: 'left', width: '40%' }}>
                            {
                                value === "" ? <div >
                                    <Form className="m-2 p-2" onChange={(e) => handleClick(e.target.id)} >

                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="1" label="True" name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="2" label="False" name="group1" />

                                    </Form>


                                </div> : null
                            }

                            {
                                value === "1" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Correct!</h2>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "2" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>The answer is true</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Slide>
    );
}

export default Slide3;
