import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

const Slide1 = () => (
    <Slide >
        <section >
            <div style={{ width: '70%',   margin: 'auto' }} >
                <div className='m-3' style={{ textAlign: 'left', fontSize: '40px' }} ><strong>ZEVs, primarily electric vehicles (EVs), have seen rapid advancements in:</strong></div>
                <div style={{ height: '50px' }} />
                <div className='m-3' style={{ backgroundColor: '#e6e6e6', width: '70%', padding: '20px', translate: '30px' }}>
                    {/* <ul style={{  textAlign: 'left' }}> */}
                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M647.65-65.87v-127.26H524.83v-79.78h122.82v-122.26h79.22v122.26H854.7v79.78H726.87v127.26h-79.22Zm-305.39-79.22Zm-39.61 79.22q-17.27 0-28.72-11.45t-11.45-28.16v-685.04q0-16.64 11.45-28.41t28.72-11.77h87.74v-64h179.22v64h88.3q16.71 0 28.16 11.77t11.45 28.41V-466q-20.52-2.26-40.61-.54-20.08 1.71-39.17 7.84v-292.21H342.26v605.82h133.35q10.13 23.22 24.39 43.03 14.26 19.8 33.35 36.19h-230.7Z" /></svg>
                        <p className='px-2' style={{ textAlign: 'left' }}>Batteries</p>
                    </div>

                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M399.22-174.04 670.3-501.13H494.83l34.3-273.57-246.22 355.27h151.18l-34.87 245.39ZM307.56-64.17l40-280H139.09l382.04-552.22h98.65l-40 320h251.31l-426 512.22h-97.53Zm169.83-410.7Z" /></svg>
                        <p className='px-2' style={{ textAlign: 'left' }}>Charging</p>
                    </div>

                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="m626.03-673.43-43.9-44 60-60H310.87v-66.79h332.39l-61.13-60 44-44 137.39 137.39-137.49 137.4ZM185.09-196.22h589.82v-208.91H185.09v208.91Zm102.74-49.74q22.43 0 38.71-16 16.29-16 16.29-39 0-22.43-16.29-38.71-16.28-16.29-39-16.29-22.71 0-38.71 16.29-16 16.28-16 39 0 22.71 16 38.71t39 16Zm384.91 0q22.43 0 38.72-16 16.28-16 16.28-39 0-22.43-16.28-38.71-16.29-16.29-39-16.29-22.72 0-38.72 16.29-16 16.28-16 39 0 22.71 16 38.71t39 16ZM105.87-465.13h642.48L696.04-627H226.31l59.7 60-43.61 44-137.53-137.39 137.48-136.83 43.91 44-60 60h490.26q18.72 0 35.25 13.46 16.54 13.46 21.32 31.15l81.61 240.65v336.83q0 17.65-13.64 30.89Q827.43-27 810-27h-7q-17.65 0-30.89-12.96Q758.87-52.91 758.87-70v-47H201.13v47q0 17.09-13.24 30.04Q174.65-27 157-27h-7q-17.65 0-30.89-13.24-13.24-13.24-13.24-30.89v-394Zm79.22 60v208.91-208.91Z" /></svg>
                        <p className='px-2' style={{ textAlign: 'left' }}>Autonomous Driving</p>
                    </div>

                    <div className='d-flex align-items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M240-40q-50 0-85-35t-35-85q0-50 35-85t85-35q14 0 26 3t23 8l57-71q-28-31-39-70t-5-78l-81-27q-17 25-43 40t-58 15q-50 0-85-35T0-580q0-50 35-85t85-35q50 0 85 35t35 85v8l81 28q20-36 53.5-61t75.5-32v-87q-39-11-64.5-42.5T360-840q0-50 35-85t85-35q50 0 85 35t35 85q0 42-26 73.5T510-724v87q42 7 75.5 32t53.5 61l81-28v-8q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-32 0-58.5-15T739-515l-81 27q6 39-5 77.5T614-340l57 70q11-5 23-7.5t26-2.5q50 0 85 35t35 85q0 50-35 85t-85 35q-50 0-85-35t-35-85q0-20 6.5-38.5T624-232l-57-71q-41 23-87.5 23T392-303l-56 71q11 15 17.5 33.5T360-160q0 50-35 85t-85 35ZM120-540q17 0 28.5-11.5T160-580q0-17-11.5-28.5T120-620q-17 0-28.5 11.5T80-580q0 17 11.5 28.5T120-540Zm120 420q17 0 28.5-11.5T280-160q0-17-11.5-28.5T240-200q-17 0-28.5 11.5T200-160q0 17 11.5 28.5T240-120Zm240-680q17 0 28.5-11.5T520-840q0-17-11.5-28.5T480-880q-17 0-28.5 11.5T440-840q0 17 11.5 28.5T480-800Zm0 440q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm240 240q17 0 28.5-11.5T760-160q0-17-11.5-28.5T720-200q-17 0-28.5 11.5T680-160q0 17 11.5 28.5T720-120Zm120-420q17 0 28.5-11.5T880-580q0-17-11.5-28.5T840-620q-17 0-28.5 11.5T800-580q0 17 11.5 28.5T840-540ZM480-840ZM120-580Zm360 120Zm360-120ZM240-160Zm480 0Z"/></svg>
                    <p className='px-2' style={{ textAlign: 'left' }}>Vehicle 2 Grid (V2G)</p>
                    </div>

                </div>
            </div>
        </section>
    </Slide>
);

export default Slide1;
