import React from 'react';
// import Image from 'react-bootstrap/Image'
import Slide from '../../Components/RevealComponents/Slide';

import farm from '../../images/econimpactslides/farm.jpg'



const Slide4 = () => (
    <Slide>
        <section className='h-100' data-auto-animate data-background-image={farm} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1} >
            <div className='w-100 h-100 d-flex flex-fill' >
                <div className="w-50 h-100 d-flex justify-content-center align-items-center" style={{ fontSize: '30px', padding: '20px', color: 'white' }}>
                    <div style={{ height: '10000px', width: '1000px', backgroundColor: 'rgba(72, 145, 92, 0.8)', position: 'fixed', right: '50%', top: '-150%', zIndex: '-999' }} />
                    <strong>The Benefits of Driving Sustainable Agriculture</strong>

                </div>
                <div className="w-50 h-100 d-flex justify-content-center align-items-center" style={{ fontSize: '30px', textAlign: 'left', padding: '20px' }}>
                <div style={{ height: '10000px', width: '1000px', backgroundColor: 'rgba(255, 255, 255, 0.8)', position: 'fixed', left: '50%', top: '-150%', zIndex: '-999' }} />
                    <div style={{ padding: '15px', textAlign: 'left', translate:'40px' }}>
                        <div >
                            <strong>Cleaner Operations</strong>
                            <p style={{ fontSize: '14px' }} >MHDZEVs significantly reduce emissions, helping Canadian farmers meet stringent environmental standards while contributing to national goals for reducing greenhouse gases.</p>
                        </div>
                        <div >
                            <strong>Enhanced Efficiency</strong>
                            <p style={{ fontSize: '14px' }} >With consistent power delivery and lower maintenance needs, MHDZEVs increase operational efficiency across Canada's diverse agricultural landscapes, from the prairies to the Maritimes.</p>

                        </div>
                        <div >
                            <strong>Cost Savings</strong>
                            <p style={{ fontSize: '14px' }} >MHDZEVs offer long-term savings on fuel and maintenance, providing Canadian farmers with a buffer against fluctuating energy prices and helping to stabilize operating costs.</p>
 
                        </div>
                        <div  >
                            <strong>Cleaner Operations</strong>
                            <p style={{ fontSize: '14px' }} > As technology evolves and policy becomes more stringent, MHDZEVs will integrate with advancements in precision agriculture, helping Canadian farmers boost
                                productivity while minimizing their environmental impact. Adopting MHDZEVs now allows farmers to be future-ready with their agricultural practices.
                            </p>


                        </div>
                    </div>
                </div>


            </div>
        </section>
    </Slide>
);

export default Slide4;
