import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

const TitleSlide = () => (
  <Slide transition='none'>
    <section data-transition='none'>
      <div style={{ color: 'white', backgroundColor: '#276e42',  margin: 'auto', width: '70%', textAlign: 'left', paddingLeft: '30px', translate: '-10% 35%' }}>
        <p style={{ fontSize: '70px', padding: '20px' }}><strong>Market Trends and Economic Factors</strong></p>

      </div>
    </section>
  </Slide>
);

export default TitleSlide;
