import React from 'react';
import Figure from 'react-bootstrap/Figure';
import Slide from '../../Components/RevealComponents/Slide';

import ab from '../../images/policyslides/policy-15-alberta.png'
import bc from '../../images/policyslides/policy-15-britishcolumbia.png'

const Slide10 = () => (
    <Slide >
       
        <section >
            <div className='d-flex justify-content-center'>
                <div className='p-3'>
                    <div style={{ fontSize: '30px', textAlign: 'left' }}><strong>Peaks to Prairies</strong></div>
                    <Figure>
                        <Figure.Image
                            width={400}
                            height={200}
                            alt="Southern Alberta"
                            src={ab}
                        />
                        <Figure.Caption style={{ backgroundColor: '#276e42', color: 'white', fontSize: '40px', textAlign: 'left', padding: '10px', width: '80%', zIndex: '999', translate: '-15px -34px'  }}>
                            Southern Alberta
                        </Figure.Caption>
                    </Figure>
                </div>

                <div className='p-3'>
                    <div style={{ fontSize: '30px', textAlign: 'left' }}><strong>Accelerate Kootenays</strong></div>
                    <Figure>
                        <Figure.Image
                            width={400}
                            height={200}
                            alt="British Columbia"
                            src={bc}
                        />
                        <Figure.Caption style={{ backgroundColor: '#276e42', color: 'white', fontSize: '40px', textAlign: 'left', padding: '10px', width: '80%', zIndex: '999', translate: '-15px -46px' }}>
                            British Columbia
                        </Figure.Caption>
                    </Figure>
                </div>
            </div>
            <div >
                <p style={{ textAlign: 'left', margin: 'auto', width: '90%', fontSize: '35px' }}> These projects have led to 32 high-speed charging stations as largely grassroots initiatives with support and funding from governments and other partners.</p>
            </div>
        </section>
    </Slide>
);

export default Slide10;
