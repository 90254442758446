import React from 'react';
import TitleSlide from './TitleSlide';

import HomeSlide from './HomeSlide';
import AirPollution from './AirPollution';
import NoiseLevels from './NoiseLevels';
import CarbonMonoxide from './CarbonMonoxide';
import FireHazards from './FireHazards';

export default [
  <TitleSlide key="1"/>,
  <HomeSlide key="2"/>,
  <AirPollution key="3"/>,
  <NoiseLevels key="4"/>,
  <CarbonMonoxide key="5"/>,
  <FireHazards key="6"/>
];
