import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

import forest from '../../images/policyslides/policy-13-forest.png'

const Slide6 = () => (
    <Slide  >
       <section data-prevent-swipe data-background-image={forest} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1} data-background-color='black'  >
            <h2 style={{ translate: '30px 30px', backgroundColor: "#276e42", color: 'white', textAlign: 'left', padding: '20px', fontSize: '50px' }}>Rural and remote regions are implementing programs to facilitate EV adoption.</h2>
       </section>
       
    </Slide>
);

export default Slide6;
