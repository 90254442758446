import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

const Slide5 = () => (
    <Slide >
        <section style={{ margin: 'auto' }}>

            <div  style={{ width:'90%', color: 'white', backgroundColor: '#276e42',  padding: '20px', fontSize: '40px', fontWeight: 'bold', textAlign: 'left', translate: '0 120%' }}>The economic benefit doesn't have to be limited to vehicle owners.</div>
        </section>
    </Slide>
);

export default Slide5;
