import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import pic1 from '../../images/overview/overview-8-rural.png'
import pic2 from '../../images/overview/overview-9-indigenous.png'
import pic3 from '../../images/overview/overview-10-farming.png'
import pic4 from '../../images/overview/overview-11-trucking.png'
import pic5 from '../../images/overview/overview-12-smallbusiness.png'

const Slide3 = () => (
    <Slide >
        <section >
            <div style={{ margin: 'auto' }}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '70%', color: '#276e42', translate: '40px' }} ><strong>Our Focus Areas</strong></div>
                <div style={{ height: '20px' }} />

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', width: '80%', translate: '40px' }}>
                    We are committed to raising awareness and providing resource for:
                </p>

                <div style={{ height: '40px' }} />

                <div className='r-stack' style={{ margin: 'auto'}}>
                    <div className='fragment fade-out' data-fragment-index="1" style={{ backgroundColor: '#e6e6e6', width: '58%', padding: '10px 60px 30px 60px' }}>
                        <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', lineHeight: '30px' }}><strong>Rural and Remote Communities</strong></p>
                        <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', lineHeight: '30px' }}>
                            Reliable transportation and reduced fuel costs can significantly benefit these areas.
                        </p>
                        <Image src={pic1} alt='Rural' height='35%' style={{ position: 'absolute', bottom: '30px', translate: '-450px' }} />
                    </div>

                    <div className='fragment fade-in-and-out' data-fragment-index="1" style={{ backgroundColor: '#e6e6e6', width: '59%', padding: '10px 60px 30px 60px' }}>
                        <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', lineHeight: '30px', width: '50%' }}><strong>Indigenous Communities</strong></p>
                        <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', lineHeight: '30px' }}>
                            ZEVs can support sustainable development and align with environmental stewardship values.
                        </p>
                        <Image src={pic2} alt='Rural' height='35%' style={{ position: 'absolute', bottom: '30px', translate: '-450px' }} />
                    </div>

                    <div className='fragment fade-in-and-out' data-fragment-index="2" style={{ backgroundColor: '#e6e6e6', width: '59%', padding: '10px 60px 30px 60px' }}>
                        <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', lineHeight: '30px', width: '50%' }}><strong>Farming Regions</strong></p>
                        <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', lineHeight: '30px' }}>
                            ZEVs offer benefits for agriculture operations, including lower operating costs and reduced emissions.
                        </p>
                        <Image src={pic3} alt='Rural' height='35%' style={{ position: 'absolute', bottom: '30px', translate: '-450px' }} />
                    </div>

                     <div className='fragment fade-in-and-out' data-fragment-index="3" style={{ backgroundColor: '#e6e6e6', width: '59%', padding: '10px 60px 30px 60px' }}>
                        <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', lineHeight: '30px', width: '50%' }}><strong>Trucking Industry</strong></p>
                        <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', lineHeight: '30px' }}>
                            ZEVs can transform freight transport with lower emissions and improved efficiency, contributing to a greener supply chain.
                        </p>
                        <Image src={pic4} alt='Rural' height='35%' style={{ position: 'absolute', bottom: '30px', translate: '-450px' }} />
                    </div>

                    <div className='fragment fade-in' data-fragment-index="4" style={{ backgroundColor: '#e6e6e6', width: '58%', padding: '10px 60px 30px 60px' }}>
                        <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', lineHeight: '30px', width: '50%' }}><strong>Small Businesses</strong></p>
                        <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', lineHeight: '30px' }}>
                            Adopting ZEVs can help small businesses save on transportation costs and take advantage of government incentives.
                        </p>
                        <Image src={pic5} alt='Rural' height='35%' style={{ position: 'absolute', bottom: '30px', translate: '-450px' }} />
                    </div> 
                </div>



            </div>
        </section>

        {/* <section data-auto-animate >
            <div style={{ position: 'fixed', top: '50%', transform: 'translateY(-50%)', left: '10%' }}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '70%', color: '#276e42', translate: '40px' }} ><strong>Our Focus Areas</strong></div>
                <div style={{ height: '20px' }} />

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', width: '80%' }}>
                    We are committed to raising awareness and providing resource for:
                </p>

                <div style={{ height: '40px' }} />




            </div>
        </section>

        <section data-auto-animate>
            <div style={{ position: 'fixed', top: '50%', transform: 'translateY(-50%)', left: '10%' }}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '70%', color: '#276e42', translate: '40px' }} ><strong>Our Focus Areas</strong></div>
                <div style={{ height: '20px' }} />

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', width: '80%' }}>
                    We are committed to raising awareness and providing resource for:
                </p>

                <div style={{ height: '40px' }} />




            </div>
        </section>

        <section data-auto-animate>
            <div style={{ position: 'fixed', top: '50%', transform: 'translateY(-50%)', left: '10%' }}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '70%', color: '#276e42', translate: '40px' }} ><strong>Our Focus Areas</strong></div>
                <div style={{ height: '20px' }} />

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', width: '80%' }}>
                    We are committed to raising awareness and providing resource for:
                </p>

                <div style={{ height: '40px' }} />


            </div>
        </section>

        <section data-auto-animate>
            <div style={{ position: 'fixed', top: '50%', transform: 'translateY(-50%)', left: '10%' }}>
                <div className='m-3' style={{ textAlign: 'left', fontSize: '50px', width: '70%', color: '#276e42', translate: '40px' }} ><strong>Our Focus Areas</strong></div>
                <div style={{ height: '20px' }} />

                <p className='m-3' style={{ textAlign: 'left', fontSize: '30px', width: '80%' }}>
                    We are committed to raising awareness and providing resource for:
                </p>

                <div style={{ height: '40px' }} />



            </div>
        </section> */}
        {/* <div style={{ backgroundColor: '#e6e6e6', width: '100px', height: '300px', position: 'absolute', zIndex: '-999', top: '160px', left: '-30px' }} /> */ }

    </Slide>
);

export default Slide3;



