import React from 'react';
// import Reveal from 'reveal.js';
import Slide from '../../Components/RevealComponents/Slide';

import graph1 from '../../images/econimpactslides/graph1.png'
import graph2 from '../../images/econimpactslides/graph2.png'


const LifeCycleSlide = () => {


  return (
    <Slide className='d-flex'>
      <div style={{ height: '90px' }} />
      <section data-auto-animate >
        <h2 >Life Cycle analyses of Environmental Impact</h2>
        <div className='d-flex ' >

          <div className='align-content-center' style={{ width: '50%', padding: '20px', margin: 'auto' }} >
            <img src={graph1} alt='Up-Front Graph' />
          </div>

        </div>
      </section>
      <section data-auto-animate>
        <h2 >Life Cycle analyses of Environmental Impact</h2>
        <div className='d-flex' >
          <div className='align-content-center' style={{ width: '50%', padding: '20px', textAlign: 'left' }} >
            <img src={graph1} alt='Up-Front Graph' />
          </div>

          <div className='align-content-center' style={{ width: '50%', padding: '20px' }} >
            <img src={graph2} alt='Lifetime Graph' />
          </div>
        </div>
      </section>
      <section data-auto-animate>
        <h2 >Life Cycle analyses of Environmental Impact</h2>
        <div className='d-flex' >
          <div className='align-content-center' style={{ width: '50%', padding: '20px' }} >
            <img src={graph2} alt='Lifetime Graph' />
          </div>

          <div className='align-content-center' style={{ width: '50%', padding: '20px', textAlign: 'left', fontSize: '8px' }} >
            {/* https://link.springer.com/article/10.1007/s10098-021-02209-6 */}
            <h2 style={{ fontSize: '25px' }}>A Chinese <a href='https://doi.org/10.1007/s10098-021-02209-6' style={{ color: '#276e42', fontStyle: 'normal' }}>study</a> found that EVs lower</h2>
            <ul >
              <li style={{ fontSize: '25px' }}>Fossil energy consumption by 40.1%</li>
              <li style={{ fontSize: '25px' }}>Greenhouse gas emissions by 26.6%</li>
            </ul>
            <hr />
            {/* https://doi.org/10.1111/j.1530-9290.2012.00532.x */}
            <h2 style={{ fontSize: '25px' }}>Similarly, a European <a href='https://doi.org/10.1111/j.1530-9290.2012.00532.x' style={{ color: '#276e42', fontStyle: 'normal' }}>study</a> found that based on the current European electricity mix, EVs lower</h2>
            <ul>
              <li style={{ fontSize: '25px' }}>Global warming potential by 10-24%</li>
            </ul>
          </div>
        </div>
      </section>
    </Slide>
  );
};

export default LifeCycleSlide;
