import React from 'react';
import Figure from 'react-bootstrap/Figure';
import Slide from '../../Components/RevealComponents/Slide';

import europe from '../../images/econfactorslides/economic-8-eu.png'
import china from '../../images/econfactorslides/economic-8-china.png'
import americacanada from '../../images/econfactorslides/economic-8-america.png'

const Slide3 = () => {

    return (
        <Slide >
            <section>
                <div style={{ margin: 'auto' }}>

                    <div style={{ textAlign: 'left', fontSize: '50px', fontWeight: 'bold', width: '85%', margin: 'auto', padding: '20px' }}>By the end of the decade, zero-emission commercial vehicles are projected to capture</div>

                    <p style={{ textAlign: 'left', padding: '10px', width: '90%', margin: 'auto' }}><strong style={{ fontSize: '60px', backgroundColor: '#276e42', color: 'white', padding: '10px' }}>28% share</strong></p>

                    <div style={{ textAlign: 'left', fontSize: '50px', fontWeight: 'bold', width: '85%', margin: 'auto', padding: '20px' }}>of the combined markets</div>

                    <div className='d-flex'>
                        <Figure style={{ width: '33%' }}>
                            <Figure.Image src={europe} alt='Europe' />
                            <Figure.Caption style={{ fontSize: '40px', color: 'black', fontWeight: 'bold' }}>Europe</Figure.Caption>
                        </Figure>

                        <Figure style={{ width: '33%' }}>
                            <Figure.Image src={china} alt='China' />
                            <Figure.Caption style={{ fontSize: '40px', color: 'black', fontWeight: 'bold' }}>China</Figure.Caption>
                        </Figure >

                        <Figure style={{ width: '33%' }}>
                            <Figure.Image src={americacanada} alt='America/Canada' />
                            <Figure.Caption style={{ fontSize: '40px', color: 'black', fontWeight: 'bold' }}>America</Figure.Caption>
                        </Figure>
                    </div>
                </div>
            </section>

        </Slide>
    );
};

export default Slide3;
