import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

import img from '../../images/icons/right-arrow.png'

const Slide5 = () => (
    <Slide  >
        <section data-prevent-swipe>
            <div style={{ backgroundColor: '#e6e6e6', textAlign: 'left', padding: '20px', translate: '60xp', width: '80%' }}>
                <p style={{ translate: '-30px', fontSize: '40px' }}><strong style={{ color: 'white', backgroundColor: 'black', paddingTop: '5px', paddingLeft: '40px', paddingRight: '10px', marginRight: '10px' }}>Canada</strong> is a signatory to the Global</p>
                <p style={{ translate: '-30px', fontSize: '40px', paddingLeft: '40px' }}>Memorandum of Understanding on </p>
                <p style={{ translate: '-30px', fontSize: '40px', paddingLeft: '40px' }}>Zero-Emission Medium- and Heavy-</p>
                <p style={{ translate: '-30px', fontSize: '40px', paddingLeft: '40px' }}> Duty Vehicles, which seeks to have:</p>
                
            </div>
            <div className='d-flex' style ={{ width: '80%', margin: 'auto', marginTop: '40px'}}>
                <div style={{ translate: '10px',backgroundColor: '#e6e6e6', fontSize:'40px', padding: '10px 40px', width:'49%', lineHeight: '60px' }}>
                    <p><strong>30%</strong> MHDZEV</p> 
                    <p>sales by </p>
                    <p><strong style={{ fontSize:'80px' }}>2030</strong></p>
                </div>
                <div style={{ margin: 'auto', zIndex:'999' }}>
                <img src={img} alt='arrows' height='96px' />
                </div>
                <div style={{ translate: '-10px', backgroundColor: '#e6e6e6', fontSize:'40px', padding: '10px 40px', paddingLeft: '20px', paddingRight: '20px', width:'49%', lineHeight: '60px' }}>
                <p><strong>100%</strong> MHDZEV</p> 
                    <p>sales by </p>
                    <p><strong style={{ fontSize:'80px' }}>2040</strong></p>
                </div>
            </div>
        </section>

    </Slide>
);

export default Slide5;
