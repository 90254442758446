import React from 'react';
import Card from 'react-bootstrap/Card'
import Slide from '../../Components/RevealComponents/Slide';


import '../../Themes/dashboard.css';

const HomeSlide = () => {

  return (
    <Slide >
      <section className='h-100 m-auto'>
        <div style={{ color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '100%', textAlign: 'center' }}>
          <p style={{ fontSize: '30px', padding: '10px' }}><strong>See how medium- and heavy-duty zero-emission vehicles (MHDZEVs) affect each of these health and safety risks.</strong></p>
        </div>


        <div style={{ padding: '50px' }} >
          <div className='d-flex justify-content-evenly align-items-center'>

            <a className='simple-nav' href="/mhdzevhealthbenefits#/2" style={{ textDecoration: 'none'}}>
              <Card className='health-cards'>
                <Card.Title>Air <br/>Pollution</Card.Title>
              </Card>
            </a>

            <a className='simple-nav' href="/mhdzevhealthbenefits#/3" style={{ textDecoration: 'none'}}>
              <Card className='health-cards'>
                <Card.Title>Noise Pollution</Card.Title>
              </Card>
            </a>

            <a className='simple-nav' href="/mhdzevhealthbenefits#/4" style={{ textDecoration: 'none'}}>
              <Card className='health-cards'>
                <Card.Title>Carbon Monoxide</Card.Title>
              </Card>
            </a>

            <a className='simple-nav' href="/mhdzevhealthbenefits#/5" style={{ textDecoration: 'none'}}>
              <Card className='health-cards'>
                <Card.Title >Fire <br/>Hazards </Card.Title>
                
              </Card>
            </a>
          </div>

          <div style={{ fontSize: '25px', paddingTop: '30px' }}>
            Click Each Option to Find Out More!
          </div>

        </div>
      </section>
    </Slide>
  )
};

export default HomeSlide;
