import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';


import graph from '../../images/econfactorslides/economic-9-graph.png'

const Slide3 = () => {

    return (
        <Slide >
            <div className='p-3' style={{ margin: 'auto' }}>
                <div style={{  textAlign: 'left', fontSize: '49px', width: '90%' }}>The transition to MHDZEVs is largely influenced by <strong style={{ color: '#276e42' }}>tightening regulations on ICE vehicles</strong> and the <strong style={{ color: '#2a2a2a'}}>decreasing total cost of ownership</strong> for electric and fuel-cell vehicles</div>


                <Image src={graph} alt='Graph'  />


            </div>
        </Slide>
    );
};

export default Slide3;