import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

const TitleSlide = () => (
  <Slide >
    <section>
      {/* <div style={{ color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '70%', fontSize: '90px', textAlign: 'left', paddingLeft: '50px', translate: '-10% 40%' }}> */}
      <div style={{ color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '80%', textAlign: 'left', paddingLeft: '50px', translate: '-10% 40%' }}>
        <p style={{ fontSize: '80px', padding: '20px' }}><strong>Technology and Innovation</strong></p>

      </div>
    </section>
  </Slide>
);

export default TitleSlide;
