import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Slide from '../../Components/RevealComponents/Slide';

import Loop from './Loop';

import toronto from '../../images/econimpactslides/environmental-5-toronto.png'

const Slide3 = () => {
    const [value, setValue] = useState("");

    // Similar to componentDidMount and componentDidUpdate:
    function handleClick(v) {
        setValue(v);
    }

    return (
        <Slide>
            <section data-auto-animate data-background-image={toronto} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1} >
                <div className='d-flex' >

                    <div style={{ width: '40%', padding: '20px', textAlign: 'left', fontWeight: 'bold' }}>
                        <div style={{ height: '70px' }} />
                        <p style={{ paddingBottom: '40px', fontSize: '40px' }}>The health and environmental impacts of ZEVs are well-studied.</p>
                    </div>

                    <div style={{ width: '10%' }} />

                    <div className='r-stack' style={{ width: '50%' }}>


                        <div className='fragment fade-out' data-fragment-index="0" style={{ padding: '10px', color: 'white' }}>
                            <div style={{ height: '10000px', width: '1000px', backgroundColor: 'rgba(72, 145, 92, 0.8)', position: 'fixed', left: '45%', top: '-150%', zIndex: '-999' }} />

                            <p style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold', width: '90%' }}><a href='https://civmin.utoronto.ca/can-electric-vehicles-save-the-planet/' style={{ color: '#262626', fontStyle: 'normal' }}>
                                Research</a> has found that every <strong style={{ fontSize: '45px' }}>10%</strong> of cars and SUVs in the GTA and Hamilton area converted to ZEVs would save
                            </p>

                            <div className='d-flex justify-content-center align-items-center' >
                                <p style={{ fontSize: '80px' }}>31</p>
                                <p style={{ fontSize: '25px', fontWeight: 'bold', textAlign: 'left', paddingLeft: '20px' }}>Lives / <br /> Year</p>
                            </div>

                            <Loop num={31} isLarge />
                        </div>

                        <div className='fragment fade-in' data-fragment-index="0" style={{ padding: '10px', color: 'white' }}>
                            <div style={{ height: '10000px', width: '1000px', backgroundColor: 'rgba(72, 145, 92, 0.8)', position: 'fixed', left: '45%', top: '-150%', zIndex: '-999' }} />

                            <p style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold', width: '90%' }}>Similarly, <a href='https://doi.org/10.1038/s41893-023-01219-0' style={{ color: '#262626', fontStyle: 'normal' }}>
                                research</a> has shown that every <strong style={{ fontSize: '45px' }}>10%</strong> of medium- and heavy-duty vehicles converted in the Chicago region would save</p>

                            <div className='d-flex justify-content-center align-items-center' >
                                <div className='p-1 m-1' style={{ fontSize: '80px' }}>203</div>
                                <div className='p-1 m-1' style={{ fontSize: '25px', fontWeight: 'bold', textAlign: 'left', paddingLeft: '20px' }}>Lives / <br /> Year</div>
                            </div>

                            <Loop num={203} />
                        </div>
                    </div>
                </div>
            </section>

            {/* <section data-auto-animate data-background-image={toronto} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1} >
            <div className='d-flex'>

                <div style={{ width: '40%', padding: '20px', textAlign: 'left', fontWeight: 'bold' }}>
                    <div style={{ height: '70px' }} />
                    <p style={{ paddingBottom: '40px', fontSize: '40px' }}>The health impacts of the environmental impacts of ZEV have been studied by</p>
                    <p style={{ fontSize: '40px', fontWeight: '900' }}><strong>Chicago</strong></p>
                </div>

                <div style={{ width: '10%' }} />


            </div>


        </section> */}

            <section data-auto-animate data-background-image={toronto} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1} >
                <div style={{ height: '10000px', width: '10000px', backgroundColor: 'rgba(72, 145, 92, 0.8)', position: 'fixed', top: '-2000px', right: '-1000px', zIndex: '-999' }} />

                <div style={{ padding: '60px' }}>




                    <p style={{ color: 'white', textAlign: 'left' }}>
                        Reducing emissions from the MHDV sector addresses climate change and significantly benefits public health, especially in disadvantaged communities, which are disproptionately affected by transportation pollution.
                    </p>
                    <br />
                    <br />
                    <p className='fragment fade-left' data-fragment-index="0" style={{ color: 'white', textAlign: 'left' }}>
                        There is already momentum from governments, manufacturers, and fleet operators aiming for a significant shift toward electrification.
                    </p>
                </div>
            </section>

            <section className='h-100 m-auto' data-auto-animate data-background-image={toronto} data-background-size="cover" data-background-position="center" data-background-repeat="no repeat" data-background-opacity={1}>
                <div className='r-stack '>
                    <div >
                        <div style={{ textAlign: 'left', width: '95%' }}>
                            <p style={{ backgroundColor: '#276e42', color: 'white', padding: '25px', fontSize: '30px' }} >
                            Which of the following is a primary environmental benefit of using medium- and heavy-duty zero-emission vehicles (MHDZEVs) compared to their diesel counterparts?
                            </p>
                        </div>

                        <div className='r-stack' style={{ backgroundColor: 'rgba(226, 226, 226, 0.5)' }}>
                            {
                                value === "" ? <div style={{ margin: 'auto', textAlign: 'left' }}>
                                    <Form className="m-2 p-2" onChange={(e) => handleClick(e.target.id)} >

                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="1" label="Increased energy consumption due to the high power requirements of heavy-duty applications." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="2" label="Higher water consumption during the production of zero-emission vehicles." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="3" label="Significant reduction in greenhouse gas emissions and air pollutants such as nitrogen oxides and particulate matter." name="group1" />
                                        <hr />
                                        <Form.Check style={{ fontSize: '30px' }} type='radio' id="4" label="No change in noise pollution levels compared to traditional diesel vehicles." name="group1" />
                                    </Form>


                                </div> : null
                            }

                            {
                                value === "1" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Significant reduction in greenhouse gas emissions and air pollutants such as nitrogen oxides and particulate matter.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "2" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Significant reduction in greenhouse gas emissions and air pollutants such as nitrogen oxides and particulate matter.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "3" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Correct!</h2>
                                    <p>Significant reduction in greenhouse gas emissions and air pollutants such as nitrogen oxides and particulate matter.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                            {
                                value === "4" ? <div className='fragment fade-out' data-fragment-index="0" style={{ margin: 'auto', textAlign: 'left', width: '90%' }}>
                                    <h2>Incorrect</h2>
                                    <p>Significant reduction in greenhouse gas emissions and air pollutants such as nitrogen oxides and particulate matter.</p>
                                    <button type='button' className="btn" style={{ fontSize: '50px' }} onClick={() => setValue("")}><i className="fa fa-arrow-left" label='button' /></button>
                                </div> : null
                            }

                        </div>
                    </div>
                </div>
            </section>

        </Slide>
    );
}

export default Slide3;
