import React from 'react';
import Slide from '../../Components/RevealComponents/Slide';

import pic from '../../images/econfactorslides/economic-10-sanfran.png'


const Slide7 = () => (
    <Slide className='h-100' >
        <div style={{ backgroundColor: '#e6e6e6', width: '50%', position: 'absolute', top: '50px', right: '10px' }}>
            <p style={{ fontWeight: 'bold', fontSize: '60px' }}>California</p>
            <p style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '35px' }}>is a leader in this department as it set ambitious regulatory targets for ZEV adoption</p>
        </div>

        <section className='h-100' data-auto-animate data-background-image={pic} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1}  >
            <div  style={{ color: 'white', translate: '10% 200%'  }}>
                <p style={{ fontWeight: 'bold', fontSize: '45px' }}>Aiming for 100% of new</p>
                <p style={{ fontWeight: 'bold', fontSize: '70px' }}>Car Sales by 2035</p>
            </div>
        </section>

        <section data-auto-animate data-background-image={pic} data-background-size="cover" data-background-position="center" data-background-repeat="repeat" data-background-opacity={1}  >
        <div  style={{ color: 'white', translate: '10% 200%'  }}>
                <p style={{ fontWeight: 'bold', fontSize: '45px' }}>Aiming for 100% of new</p>
                <p style={{ fontWeight: 'bold', fontSize: '70px' }}>MHD sales by 2045</p>
            </div>
        </section>

    </Slide>
);

export default Slide7;