import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';

import pic1 from '../../images/overview/overview-13-ecocanada.png'

import '../../Themes/dashboard.css';

import ecoImpactBtn from '../../images/eco-impact-btn.jpg'
import overviewBtn from '../../images/overview.jpg'
import healthBenefitsBtn from '../../images/healthbenefits.jpg'
import econFactorBtn from '../../images/econ-factor-btn.jpg'
import mhdzevBtn from '../../images/mhzev-btn.jpg'
import techBtn from '../../images/tech-btn.jpg'
import policyBtn from '../../images/policy-btn.jpg'


const Sidebar = () => {



  return (
    <div className='' >


      {/* DESKTOP */}
      <Navbar expand="lg" className=" h-100 d-none d-lg-flex flex-column bg-body-tertiary sidebar jusity-content-between align-items-between " id="sidebar" style={{ width: '320px' }}>

        <Nav variant="underline" className="flex-column flex-grow-1 pe-3" defaultActiveKey="/" >
          <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${overviewBtn})`, backgroundSize: 'cover' }}>
            <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/" style={{ color: 'white', fontSize: '20px' }}>
              <span className="material-symbols-outlined">
                home
              </span>
              <span className='mx-2' >Overview</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${techBtn})`, backgroundSize: 'cover' }}>
            <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/technologyandinnovation" style={{ color: 'white', fontSize: '20px' }}>
              Technology and Innovation
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${econFactorBtn})`, backgroundSize: 'cover' }}>
            <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/economicfactors" style={{ color: 'white', fontSize: '20px' }}>
              Economic Factors
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${ecoImpactBtn})`, backgroundSize: 'cover' }}>
            <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/environmentalimpact" style={{ color: 'white', fontSize: '20px' }}>
              Environmental Impact
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${healthBenefitsBtn})`, backgroundSize: 'cover' }}>
            <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/mhdzevhealthbenefits" style={{ color: 'white', fontSize: '20px' }}>
              Health Benefits
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${policyBtn})`, backgroundSize: 'cover' }}>
            <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/policiesandincentives" style={{ color: 'white', fontSize: '20px' }}>
              Policies and Incentives
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${mhdzevBtn})`, backgroundSize: 'cover' }}>
            <Nav.Link className="nav-link d-flex justify-content-center align-items-center" href="/mhdzevcomparison" style={{ color: 'white', fontSize: '20px' }}>
              MHDZEV Comparison
            </Nav.Link>
          </Nav.Item>

        </Nav>

        <div style={{ margin: 'auto' }} className='my-3 py-3 d-flex justify-content-center align-items-center'>

         
          <a href="https://eco.ca/educators/indigenous-and-small-business-awareness-for-combating-climate-change-through-adoption-of-zero-emission-medium-and-heavy-vehicles/" style={{ textAlign: 'center' }}>
            <Image className='grow' src={pic1} alt='Rural' height='50%' width='50%' />
          </a>
        </div>
      </Navbar>


    </div>
  );


  // <Navbar className="sidebar">

  //   <Nav >


  //     </Nav>
  // </Navbar>
};

export default Sidebar;

// <div className="sidebar">
//     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//     <span className="navbar-toggler-icon" />
//   </button>
//   <div className="collapse navbar-collapse" id="navbarSupportedContent">
//     <a style={{ backgroundColor: '#0e5a15' }} href="/">Home</a>
//     <a style={{ backgroundImage: "url(${mhdzevBtn})", backgroundSize: 'cover' }} href="/mhdzev">What is a MHDZEV?</a>
//     <a style={{ backgroundImage: "url(${techBtn})", backgroundSize: 'cover' }} href="/technologyandinnovation">Technology + Innovation</a>
//     <a style={{ backgroundImage: "url(${econFactorBtn})", backgroundSize: 'cover' }} href="/economicfactors">Economic Factors</a>
//     <a style={{ backgroundImage: "url(${ecoImpactBtn})", backgroundSize: 'cover' }} href="/environmentalimpact">Environmental Impact</a>
//     <a style={{ backgroundImage: "url(${policyBtn})", backgroundSize: 'cover' }} href="/policiesandincentives">Policies + Incentives</a>
//     </div>
//   </div>