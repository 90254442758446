import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import car1 from '../../images/comparisonslides/zevbyd6f.png'
import car2 from '../../images/comparisonslides/hino268.png'

const Class6 = () => (
    <Slide >
        <section>
            <div className='d-flex justify-content-between align-items-center' style={{ fontSize: '30px', color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '100%', textAlign: 'center' }}>
            <p className='p-2'><strong>Class 6: BYD 6F</strong></p>
            <a className='d-flex justify-content-center align-items-center' href='mhdzevcomparison#/1' style={{ textDecorationLine: 'none' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" /></svg>
                    <div style={{ color: 'white', fontStyle: 'normal', paddingRight: '20px', textAlign: 'left' }}>Back</div>
                </a>
            </div>

            <div style={{ padding: '1px' }} >
                <div className='d-flex justify-content-center align-items-center'>
                    <div style={{ textAlign: 'center', margin: '20px', width: '50%' }}>
                        <Image src={car1} style={{ height: '85%', width: '85%' }} alt='BYD 6F' />

                    </div>

                    <div style={{ textAlign: 'center', margin: '20px', width: '50%' }}>
                        <Image src={car2} style={{ height: '75%', width: '75%' }} alt='Hino 268a' />

                    </div>

                </div>
                <div className='d-flex justify-content-between align-items-start'>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                    <div style={{ fontSize: '25px' }}>BYD 6F</div>
                        <ul >
                            <li style={{ fontSize: '15px' }}><strong>Range: </strong>Up to 300km (186 miles)</li>
                            <li style={{ fontSize: '15px' }}><strong>Gross Vehicle Weight Rating: </strong>Up to 26,000 lbs</li>
                            <li style={{ fontSize: '15px' }}><strong>Peak Power: </strong>523 Horsepower</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>Up to $75,000</li>
                            {/* <li style={{ fontSize: '15px' }}><strong>Total Cost: </strong>Price is not publicly available, but approximately upwards of $200,000</li> */}
                        </ul>
                    </div>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                    <div style={{ fontSize: '25px' }}>Hino 268a</div>
                        <ul>
                            <li style={{ fontSize: '15px' }}><strong>Fuel Type: </strong>Diesel</li>
                            <li style={{ fontSize: '15px' }}><strong>Gross Vehicle Weight Rating: </strong>Up to 25,950 lbs</li>
                            <li style={{ fontSize: '15px' }}><strong>Peak Power: </strong>230 Horsepower</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>N/A</li>
                            {/* <li style={{ fontSize: '15px' }}><strong>Total Cost: </strong>Upwards from $96,000</li> */}
                        </ul>
                    </div>
                </div>

            </div>
            
            </section>
    </Slide>
);

export default Class6;
