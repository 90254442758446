
import React, { useState } from 'react'; // 
import Slide from '../../Components/RevealComponents/Slide';

// data-background-image={cali} data-background-size="50%" data-background-position="right" data-background-repeat="no repeat" data-background-opacity={Math.floor(value/10)/10}
import cali from '../../images/econimpactslides/newcali2.jpg'

const Slide2 = () => {
    const [value, setValue] = useState(80);

    // Similar to componentDidMount and componentDidUpdate:
    function handleClick(v) {
        setValue(v);
    }

    return (
        <Slide data-prevent-swipe>

            <section className='r-stretch' >

                <div className='d-flex' >



                    <div className=' ' style={{ height: '100%', width: '60%', padding: '20px', textAlign: 'left' }}>
                        <div style={{ height: '90px' }} />
                        <div style={{ width: '75%', margin: 'auto', fontWeight: 'bold' }}>
                            <p style={{ paddingBottom: '40px', fontSize: '37px' }}>Cleaner environments resulting from ZEV use lead to significant health improvements and economic benefits.</p>
                           
                        </div>
                    </div>

                    <div className='r-stack' style={{ translate: '20px', width: '43%' }}>
                        <img src={cali} alt='California' height="800px" width="400px" style={{ zIndex: '-999', opacity: 1 - (Math.floor(value / 10) / 10), objectFit: 'cover' }} />

                        <div style={{ translate: '0 -20%'}}>
                            <div style={{ backgroundColor: "rgb(38, 110, 66, 0.7)", color: 'white', textAlign: 'center', padding: '20px' }}>
                                <p style={{ fontSize: '30px' }}>Slide to reduce ground level ozone and P2.5 concentrations</p>

                                <input style={{ transform: 'scale(2.3)', margin: '10px', accentColor: '#262626' }} type="range" min="0" max="60" value={value} onChange={(e) => handleClick(e.target.value)} className="slider" id="myRange" />

                            </div>
                            <div style={{ zIndex: '-1', marginTop: '40px', opacity: 1 - (Math.floor(value / 10) / 10), color: 'white' }}>
                                <p style={{
                                    fontSize: '60px',
                                    fontWeight: 'bolder',
                                    
                                }}>$$ Billions</p>
                                <p style={{
                                    fontSize: '40px',
                                    
                                }} ><strong>in health saving and other benefits</strong></p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </Slide>
    );
};

export default Slide2;


