import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import car1 from '../../images/comparisonslides/zevmicrobird.png'
import car2 from '../../images/comparisonslides/rampromaster.png'

const Class4 = () => (
    <Slide >
        <section>
            <div className='d-flex justify-content-between align-items-center' style={{ fontSize: '30px', color: 'white', backgroundColor: '#276e42', margin: 'auto', width: '100%', textAlign: 'center' }}>
            <p className='p-2'><strong>Class 4: Micro Bird Commercial G5 Electric</strong></p>
            <a className='d-flex justify-content-center align-items-center' href='mhdzevcomparison#/1' style={{ textDecorationLine: 'none' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" /></svg>
                    <div style={{ color: 'white', fontStyle: 'normal', paddingRight: '20px', textAlign: 'left' }}>Back</div>
                </a>
            </div>

            <div style={{ padding: '1px' }} >
                <div className='d-flex justify-content-center align-items-center'>
                    <div style={{ textAlign: 'center', width: '50%' }}>
                        <Image src={car1} style={{ height: '75%', width: '75%' }} alt='Micro Bird Commercial G5' />

                    </div>

                    <div style={{ textAlign: 'center',  width: '50%' }}>
                        <Image src={car2} style={{ height: '75%', width: '75%' }} alt='Ram ProMaster 3500' />

                    </div>

                </div>
                <div className='d-flex justify-content-between align-items-start'>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                    <div style={{ fontSize: '25px' }}>Micro Bird Commercial G5</div>
                        <ul >
                            <li style={{ fontSize: '15px' }}><strong>Range: </strong>Up to 160 km (100 miles)</li>
                            <li style={{ fontSize: '15px' }}><strong>Payload Capacity: </strong>Typically up to 7,980 lbs, depending on the specific configuration and options, and can accommodate up to 30 passengers.</li>
                            <li style={{ fontSize: '15px' }}><strong>Peak Power: </strong>215 Horsepower</li>
                            <li style={{ fontSize: '15px' }}><strong>Charging: </strong>Compatible with Level 2 (13.2 kw) or Level 3 chargers (50 kw)</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>Eligible for up to $75,000 under the iMHZEV Program in Canada, subject to eligibility and location</li>
                            {/* <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>Not publicly available, approximately $130,000, which after incorporating the rebate would be around $55,000</li> */}
                        </ul>
                    </div>
                    <div style={{ textAlign: 'left', margin: '20px', width: '50%' }}>
                    <div style={{ fontSize: '25px' }}>Ram ProMaster 3500</div>
                        <ul>
                        <li style={{ fontSize: '15px' }}><strong>Fuel Type: </strong>Available with both Gasoline and Diesel engine options</li>
                            <li style={{ fontSize: '15px' }}><strong>Payload Capacity: </strong>The maximum payload capacity is 5,234 lbs. It can hold up to 30 Passengers.</li>
                            <li style={{ fontSize: '15px' }}><strong>Peak Power: </strong>276 Horsepower</li>
                            <li style={{ fontSize: '15px' }}><strong>Rebate: </strong>N/A</li>
                            {/* <li style={{ fontSize: '15px' }}><strong>Price: </strong>Starts at $59,615</li> */}
                        </ul>
                    </div>
                </div>

            </div>
           </section>
    </Slide>
);

export default Class4;
