import React from 'react';
import { Routes, Route } from 'react-router-dom'; // , Switch
// import { Row, Col } from 'react-bootstrap';

import SideNavbar from './Components/layout/SideNavBar';
import MobileNavBar from './Components/layout/MobileNavBar';

// import TopNavbar from './Components/layout/TopNavbar';
// import Article from './Components/layout/Article';

import Deck from './Components/RevealComponents/Deck';
// import Slides from './Slides';

import './index.css';
import './Themes/override.css';
import './Themes/dashboard.css';
// import 'reveal.js/dist/theme/simple.css';

// import Slides from './Slides/Examples';
import EcoImpactSlides from './Slides/EconImpact';
import EcoFactorsSlides from './Slides/EconFactors';
import MHDZEVSlides from './Slides/Overview';
import PoliciesIncentivesSlides from './Slides/PoliciesIncentives';
import TechInnovationSlides from './Slides/TechnologyInnovation';
import MHZEVComparison from './Slides/ComparisonTool';
import HealthBenefits from './Slides/HealthBenefits';


const App = () => {
    return (
        <div className="App ">
            <div className="h-100 d-lg-flex ">
                <SideNavbar />

                <div className="h-100 content flex-fill">
                    <Routes >
                        <Route index element={
                            <>
                                <div className='p-3 d-none d-lg-block' style={{ backgroundColor: '#276e42', fontSize: '50px', color: 'white', height: '80px' }}>
                                    <div className='mx-3 px-3'>
                                        Overview
                                    </div>
                                </div>
                                <MobileNavBar title="Overview" />
                                <Deck>{MHDZEVSlides}</Deck>
                            </>
                        } />

                        <Route path="/" element={
                            <>
                                <div className='p-3 d-none d-lg-block' style={{ backgroundColor: '#276e42', fontSize: '50px', color: 'white', height: '80px' }}>
                                    <div className='mx-3 px-3'>
                                        Overview
                                    </div>
                                </div>
                                <MobileNavBar title="Overview" />
                                <Deck>{MHDZEVSlides}</Deck>
                            </>
                        } />

                        <Route path="*" element={
                            <>
                                <div className='p-3 d-none d-lg-block' style={{ backgroundColor: '#276e42', fontSize: '50px', color: 'white', height: '80px' }}>
                                    <div className='mx-3 px-3'>
                                        Overview
                                    </div>
                                </div>
                                <MobileNavBar title="Overview" />
                                <Deck>{MHDZEVSlides}</Deck>
                            </>
                        } />

                        <Route path="/technologyandinnovation" element={
                            <>
                                <div className='p-3 d-none d-lg-block' style={{ backgroundColor: '#276e42', fontSize: '50px', color: 'white', height: '80px' }}>
                                    <div className='mx-3 px-3'>
                                        Technology and Innovation
                                    </div>
                                </div>
                                <MobileNavBar title="Technology and Innovation" />
                                <Deck>{TechInnovationSlides}</Deck>
                            </>
                        } />


                        <Route path="/economicfactors" element={
                            <>
                                <div className='p-3 d-none d-lg-block' style={{ backgroundColor: '#276e42', fontSize: '50px', color: 'white', height: '80px' }}>
                                    <div className='mx-3 px-3'>
                                        Economic Factors
                                    </div>
                                </div>
                                <MobileNavBar title="Economic Factors" />
                                <Deck>{EcoFactorsSlides}</Deck>
                            </>
                        } />


                        <Route path="/environmentalimpact" element={
                            <>
                                <div className='p-3 d-none d-lg-block' style={{ backgroundColor: '#276e42', fontSize: '50px', color: 'white', height: '80px' }}>
                                    <div className='mx-3 px-3'>
                                        Environmental Impact
                                    </div>
                                </div>
                                <MobileNavBar title="Environmental Impact" />
                                <Deck>{EcoImpactSlides}</Deck>
                            </>
                        } />


                        <Route path="/policiesandincentives" element={
                            <>
                                <div className='p-3 d-none d-lg-block' style={{ backgroundColor: '#276e42', fontSize: '50px', color: 'white', height: '80px' }}>
                                    <div className='mx-3 px-3'>
                                        Policies and Incentives
                                    </div>
                                </div>
                                <MobileNavBar title="Policies and Incentives" />
                                <Deck>{PoliciesIncentivesSlides}</Deck>
                            </>
                        } />


                        <Route path="/mhdzevcomparison" element={
                            <>
                                <div className='p-3 d-none d-lg-block' style={{ backgroundColor: '#276e42', fontSize: '50px', color: 'white', height: '80px' }}>
                                    <div className='mx-3 px-3'>
                                        MHDZEV Comparison
                                    </div>
                                </div>
                                <MobileNavBar title="MHDZEV Comparison" />
                                <Deck>{MHZEVComparison}</Deck>
                            </>
                        } />

                        <Route path="/mhdzevhealthbenefits" element={
                                <>
                                    <div className='p-3 d-none d-lg-block' style={{ backgroundColor: '#276e42', fontSize: '50px', color: 'white', height: '80px' }}>
                                        <div className='mx-3 px-3'>
                                            Health Benefits
                                        </div>
                                    </div>
                                    <MobileNavBar title="Health Benefits" />
                                    <Deck>{HealthBenefits}</Deck>
                                </>
                            } />
                    </Routes>
                </div>

            </div>
        </div>
    );
}




export default App;