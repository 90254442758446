import React from 'react'

const SpanMaker = ({ num, isLarge }) => {
    const spans = []

    for (let i = 0; i < num; i += 1) {

        if (isLarge) {
            spans.push(
                <svg xmlns="http://www.w3.org/2000/svg" key={i} height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF">
                    <path d="M479.9-680q-24.9 0-42.4-17.6T420-740.1q0-24.9 17.6-42.4t42.5-17.5q24.9 0 42.4 17.6t17.5 42.5q0 24.9-17.6 42.4T479.9-680ZM420-160v-200h-40v-200q0-24.75 17.63-42.38Q415.25-620 440-620h80q24.75 0 42.38 17.62Q580-584.75 580-560v200h-40v200H420Z" />
                </svg>
            )
        }
        else {
            spans.push(
                <svg xmlns="http://www.w3.org/2000/svg" key={i} height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF">
                    <path d="M479.9-648q-24.9 0-42.4-17.6T420-708.1q0-24.9 17.6-42.4t42.5-17.5q24.9 0 42.4 17.6t17.5 42.5q0 24.9-17.6 42.4T479.9-648ZM408-192v-168h-48v-168q0-29.7 21.15-50.85Q402.3-600 432-600h96q29.7 0 50.85 21.15Q600-557.7 600-528v168h-48v168H408Z" />
                </svg>

            )
        }

    }

    return spans
}

export default SpanMaker