import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../../Components/RevealComponents/Slide';

import pic1 from '../../images/overview/overview-13-ecocanada.png'


import '../../Themes/dashboard.css';

const Slide4 = () => (
    <Slide >
        <section >
            <div style={{ height: '50px' }} />
            <div style={{ margin:'auto' }} className='d-flex justify-content-center align-items-center'>
                
                
                <a href="https://eco.ca/educators/indigenous-and-small-business-awareness-for-combating-climate-change-through-adoption-of-zero-emission-medium-and-heavy-vehicles/" >
                    <Image className='grow' src={pic1} alt='Rural' height='50%' width='50%'  />
                </a>
            </div>
            <div style={{ margin:'auto' }} className='d-flex justify-content-center align-items-start'>
               
                <div  style={{ paddingTop: '20px', fontSize: '30px'}}>
                Explore how ECO Canada is leading the charge when it comes to understanding ZEVs.
                </div>
            </div>
            
        </section>

        
    </Slide>
);

export default Slide4;
