import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import Slide from '../../Components/RevealComponents/Slide';



const NoiseLevels = () => {
    const [showHealthNoise, setShowHealth] = useState(false);

    return (
        <Slide >
            <section className='h-100'  >
                <h2 style={{ fontWeight: 'bold', fontSize: '20px' }}>Toggle to see health benefits</h2>
                <div className='d-flex justify-content-between align-items-center'>
                <Form className='d-flex justify-content-between align-items-center' style={{  fontSize: '30px' }}>
                        <Form.Label htmlFor="custom-switch" className='m-1'>Internal Combustion Engine</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            onChange={() => setShowHealth(!showHealthNoise)}
                            checked={showHealthNoise || false}
                        />
                        <Form.Label htmlFor="custom-switch" className='m-1'>Zero-Emission Vehicle</Form.Label>
                    </Form>


                    <a className='d-flex justify-content-center align-items-center' href='mhdzevhealthbenefits#/1' style={{ textDecorationLine: 'none' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" /></svg>
                        <div style={{ fontStyle: 'normal', fontSize: '30px', paddingRight: '20px', textAlign: 'left' }}>Back</div>
                    </a>
                </div>
                {!showHealthNoise ?
                    <div style={{ backgroundColor: '#262626', padding: '30px' }}>

                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <h2>Noise Pollution</h2>
                            </div>

                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                Traditional medium and heavy-duty vehicles, such as diesel trucks, buses, and construction equipment, are significant sources of noise pollution.

                            </div>
                        </div>
                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>
                            <h3>Health Risks:</h3>
                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                <div>

                                    <strong>Hearing Issues </strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        Prolonged exposure to loud vehicle noise can result in hearing impairment and tinnitus. 1.1 billion young people are at risk due to exposure to unsafe sound levels. (World Health Organization, 2019)
                                    </p>

                                </div>
                                <div>
                                    <strong>Sleep Disruption </strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        Noise pollution can interfere with sleep, causing insomnia and poor sleep quality, which in turn impacts overall health and cognitive function. Chronic noise exposure can lead to sleep disturbances, which significantly impact cognitive function and health. (American Psychological Association, 2019)
                                    </p>

                                </div>
                                <div>
                                    <strong>Noisy</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        Persistent noise pollution lowers the quality of life in affected areas, making outdoor activities less enjoyable and reducing overall well-being.
                                    </p>

                                </div>
                            </div>


                        </div>

                    </div>
                    :
                    <div style={{ backgroundColor: '#276e42', padding: '30px' }}>

                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>

                            <h2>Peace and Quiet</h2>

                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                MHDZEVs, such as electric trucks and buses, operate much more quietly compared to traditional diesel-powered vehicles, significantly reducing noise pollution. This is because electric motors produce less mechanical noise than internal combustion engines, resulting in quieter vehicle operation.
                            </div>
                        </div>

                        <div className='my-3 py-3 w-100' style={{ color: 'white' }}>
                            <h3>Health Benefits:</h3>
                            <div style={{ textAlign: 'left', fontSize: '20px' }}>
                                <div>

                                    <strong>Protection Against Hearing Loss</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        Less exposure to loud noises helps prevent hearing impairment and tinnitus. Prolonged exposure to loud traffic noise is a significant risk factor for hearing problems, and the quieter operation of electric vehicles mitigates this risk. (Basner et al., 2014)
                                    </p>

                                </div>
                                <div>
                                    <strong>Decreased Cancer Risk</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        Quieter environments lead to better sleep quality, enhancing overall health and cognitive function. Noise pollution is known to disrupt sleep patterns, so the reduced noise from electric vehicles supports better sleep. (Basner et al., 2014)

                                    </p>

                                </div>
                                <div>
                                    <strong>Fewer Premature Deaths</strong>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }}>
                                        Lower noise levels contribute to decreased stress and anxiety, which improves mental health. Chronic exposure to high noise levels has been linked to increased stress and anxiety, making quieter electric vehicles a beneficial alternative. (Lambert, 2018)
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                }
            </section>

        </Slide>

    )
};

export default NoiseLevels;
